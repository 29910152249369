@media (min-width: 320px) {
    div.mobile-menu {
        width: 100%;
        // min-height: 80px;
        display: flex;
        flex-direction: column;
        // position: absolute;
        background-color: var(--footer);
        // overflow: hidden;
        div.bar {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            height: 80px;
            div.bar-logo {
                display: flex;
                justify-content: flex-start;
                width: 100%;
                padding: 10px 20px;
                div.logo {
                    width: 162px;
                    height: 64px;
                    background-image: url('../images/Biuro  obsługi nieruchomości_Mojsiewicz&Stanek_logo ok_przezroczyste.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
            div.h {
                font-size: 3rem;
                padding: 20px 20px;
            }
        }
        aside.sidebar.hidden {
            display: none;
        }
        aside.sidebar.visible {
            display: block;
        }
        aside.sidebar {
            // display: flex;
            height: calc(100vh);
            width: 100%;
            position: fixed;
            z-index: 10;
            top: 0;
            left: 0;
            overflow-x: hidden;
            background-color: var(--footer);

        }
        nav {
            position: relative;
            height: 100%;
            // top: 25%;
            width: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            div.bar-logo {
                display: flex;
                justify-content: center;
                width: 100%;
                padding: 10px 20px;
                margin: 30px 0;
                div.logo {
                    width: 217px;
                    height: 85px;
                    background-image: url('../images/Biuro  obsługi nieruchomości_Mojsiewicz&Stanek_logo ok_przezroczyste.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
            div.positions {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                ul {
                    list-style: none;
                    li {
                        margin: 10px 0;
                        a {
                            display: flex;
                            flex-direction: column;
                            text-decoration: none;
                            color: black;
                            text-transform: uppercase;
                            font-size: 2rem;
                            font-weight: bold;
                            padding: 10px;

                        }
                    }
                }
            }
        }
    }
}
    // nav.x {
    //     // display: none;
        
    //     width: 100%;
        
        

    //     div.bar {

    //         div.logo {
    //             width: 217px;
    //             height: 85px;
    //             background-image: url('../images/Biuro  obsługi nieruchomości_Mojsiewicz&Stanek_logo ok_przezroczyste.png');
    //             background-size: contain;
    //             background-repeat: no-repeat;
    //             background-position: center;
    //         }

    //         div.x, div.h {
    //             font-size: 3rem;
    //         }
    //     }

    //     div.menu {
    //         display: none;
    //         flex-direction: column;
    //         height: 100vh;
    //         justify-content: space-around;
    //         align-items: center;
            

    //     }
    // }


@media (min-width: 1024px) {
    div.mobile-menu {
        display: none;
    }
    nav {
        display: flex;
        // position: fixed;
        // 
        width: 100%;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        // padding: 0 30px 30px 0;
        justify-content: center;
        z-index: 1000;
    
        // top: 30px;
        div.main {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 95%;
            border-radius: 30px;
            background-color: var(--footer);
            border-radius: 20px;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.19);
            
            div.logo {
                width: 150px;
                height: 59px;
                background-image: url('../images/Biuro  obsługi nieruchomości_Mojsiewicz&Stanek_logo ok_przezroczyste.png');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                // img {
                //     height:85px;    
                //     width: auto;   
                // }
                margin: 5px 15px;

            }
            div.menu {
                display: flex;
                padding: 0 10px;
                ul {
                    display: flex;
                    flex-direction: row;
                    margin: 20px;
                    width: fit-content;
                    align-items: center;
                    list-style: none;
                    li {
                        a {
                            display: flex;
                            flex-direction: column;
                            text-decoration: none;
                            color: black;
                            text-transform: uppercase;
                            font-weight: bold;
                            padding: 10px;
                            &.active {
                                border-bottom: 2px gray solid;
                                transform: translateX(0);
                            };
                        }

                    }
                }
                button{
                    margin: auto 20px;
                }
            
            }
    
    
        }
    
    }
    
    nav.menu {
        position: sticky;
        top: 0;
        background: white;
        z-index: 1000;
        
      }
      
}


