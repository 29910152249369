:root {
    --fontAwesome: #533938; 
    --tlo: #f4eeea;
    --podswietlenie: rgb(230, 228, 228);
    --ramka: rgb(152, 152, 152);
    --ramkaLight: rgb(204, 204, 204);
    --footer: #e3d7cf;
    --podswietlenieListy: #e0d6cf;
    --wnetrzeRamki: rgb(249, 244, 240);
    --wnetrzeRamkiHover: rgb(252, 250, 248);

  }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    // list-style: none;
    
}

body {
    background-color: var(--tlo);
}

html {
    font-family: 'lato';
}

svg {
    color: var(--fontAwesome)!important;
    cursor: pointer;
}

h2 {
    font-size: 1rem;
}

.u:hover {
    text-decoration: underline;
    cursor: default;
}

a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    color: initial;
}

div.wiersz {
    display: flex;
    align-items: center;
}

div.buttons {
    display: flex;
    width: fit-content;
    align-items: center;
}

table {
    border-collapse: collapse;
    thead, tbody, tfoot {
        tr {
            th, td {
                padding: 1px 5px;
            }
            th {
                font-weight: 500;
            }
        }
    }
    tfoot {
        font-weight: 500;
        tr td {
            white-space: nowrap;
        }
    }
}

@media (min-width: 320px) {
    div.app {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        background-color: var(--tlo);
        header {
            display: none;
        }
    }
}

@media (min-width: 1024px) {
    div.app {
        
        header {
            display: block;
            flex-basis: 50vh;
            background-image: url("../../public/img/Zdjęcie WhatsApp 2023-11-19 o 18.18.02_c14b4798.jpg");
            background-position: 100% 45%;
            background-size: cover;
            &.admin {
                flex-basis: 20vh;
            }
        }
        main {
            // display: flex;
            // flex-grow: 1;
            padding: 5px 30px 0 30px;
            background-color: var(--tlo);
            min-height: 50vh;
            // width: 100%;
        }
        aside {
            min-width: 200px;
            background-color: yellow;
        }
        section.page {
            flex-grow: 1;
            background-color: var(--tlo);
        }
    }

}



input [type=text] {
    max-height: 33px;
}


button, select {
    /* CSS */
        height: fit-content;
        appearance: none;
        background-color: #FAFBFC;
        border: 1px solid rgba(27, 31, 35, 0.15);
        border-radius: 6px;
        box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
        box-sizing: border-box;
        color: #24292E;
        cursor: pointer;
        display: inline-block;
        font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        list-style: none; 
        padding: 3px 10px;
        position: relative;
        transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        vertical-align: middle;
        white-space: nowrap;
        word-wrap: break-word;
        margin: 10px 20px;
    
    &:hover {
        background-color: #F3F4F6;
        text-decoration: none;
        transition-duration: 0.1s;
    }
    
    &:disabled {
        background-color: #FAFBFC;
        border-color: rgba(27, 31, 35, 0.15);
        color: #959DA5;
        cursor: default;
    }
    
    &:active {
        background-color: #EDEFF2;
        box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
        transition: none 0s;
    }
    
    &:focus {
        outline: 1px transparent;
    }
    
    &:before {
        display: none;
    }
    
    &:-webkit-details-marker {
        display: none;
    }
}

select {
    margin: 0;
}

.noSelect {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

div.buttons {
    display: flex;
    flex-direction: row;
    width: fit-content;
}

.ramka {
    border: 1px solid var(--ramka);
    padding: 10px 10px;
    border-radius: 10px;
    background-color: var(--wnetrzeRamki);
    height: fit-content;
    min-width: 350px;
    margin: 0 0 5px 0;
    div.naglowek {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 10px 0;
        h2 {
            display: block;
            width: fit-content;
        }
        div.ikony {
            
            svg {
                display: block;
            }
        }
        svg {
            display: block;
        }
    }
}

.ramkaInside {

}

.ramka-br {
    border: 1px solid var(--ramka);
    border-radius: 15px;
    padding: 1px;
}

.ramka-br-light {
    border: 1px solid var(--ramkaLight);
    border-radius: 15px;
    padding: 1px;
}

.ramka-br2 {
    border: 2px solid var(--ramka);
    border-radius: 15px;
    padding: 1px;
}

.ramka-border-shadow {
    -webkit-box-shadow: 1px 1px 3px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: 1px 1px 3px 0px rgba(66, 68, 90, 1);
    box-shadow: 1px 1px 3px 0px rgba(66, 68, 90, 1);
}

.ramka-inset-shadow {
    -webkit-box-shadow: inset 1px 1px 3px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: inset 1px 1px 3px 0px rgba(66, 68, 90, 1);
    box-shadow: inset 1px 1px 3px 0px rgba(66, 68, 90, 1);
    padding: 3px 5px;
    border-radius: 3px;
}

.ramka-wnetrze-ramki {
    background-color: var(--wnetrzeRamki);
}

.w50 {
    width: 50px;
}

.w100 {
    width: 100px!important;
}

.p5 {
    padding: 5px;
}

span {
    &.green {
        color: green;
    }
    &.yellow {
        color: orange;
        font-weight: 800;
    }
    &.red {
        color: red;
        font-weight: 800;
    }
    &.pointer {
        cursor: pointer;
    }
    &.suma {
        font-weight: 800;
    }
}

.central, .center {
    text-align: center;
}

.red {
    color: red;
}

.right {
    text-align: right;
}

.left {
    text-align: left!important;
}

.mt-100 {
    margin-top: 100px;
}

.pointer {
    cursor: pointer;
}

.comment {
    background-color: #e2faea;
    color: green;
    padding: 10px 10px;
    border-radius: 5px;
    width: fit-content;
    margin: 15px 0;
}

.cienie {
    border-radius: 5px;
    overflow: hidden;
    -webkit-box-shadow: 1px 5px 18px -7px rgba(106, 106, 123, 1);
    -moz-box-shadow: 1px 5px 18px -7px rgba(106, 106, 123, 1);
    box-shadow: 1px 5px 18px -7px rgba(106, 106, 123, 1);
}

.hidden {
    display: none;
}

ul {
    list-style: none;
    li {
        // width: 100%;
    }
}

h2 {
    width: fit-content;
}

