div.noty {
  display: flex;
  flex-direction: column;
  width: 100%;
}
div.noty div.notyContainer {
  display: flex;
}
div.noty div.notyContainer div.lista {
  width: 45%;
  padding: 50px 0 0 0;
}
div.noty div.notyContainer div.lista h4 {
  margin-bottom: 20px;
}
div.noty div.notyContainer div.lista table.noty {
  font-size: 10px;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 20px;
}
div.noty div.notyContainer div.lista table.noty tr td, div.noty div.notyContainer div.lista table.noty tr th {
  border-collapse: collapse;
  border: 1px solid;
  padding: 3px 10px;
}
div.noty div.notyContainer div.lista.historia {
  width: 100%;
}
div.noty div.notyContainer div.preview {
  display: flex;
  justify-content: center;
  width: 55%;
}/*# sourceMappingURL=noty.css.map */