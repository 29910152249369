div.wspolnotyDodaj {
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
    width: 500px;
    height: fit-content;
    background-color: var(--wnetrzeRamki);
    div.wiersz {
        display: flex;
        justify-content: space-between;
        margin: 5px 0;
        select {
            width: fit-content;
        }
    }
    div.button {
        justify-content: center;
    }
}
div.wspolnotyEdytuj {
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
    height: fit-content;
    background-color: var(--wnetrzeRamki);
    div.wiersz {
        display: flex;
        justify-content: space-between;
        margin: 5px 0;
        select {
            width: fit-content;
        }
    }
    div.button {
        justify-content: center;
    }
}
div.wspolnotyDane {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 30px 0 0 0;
    div.naglowek {
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 0px 10px;
        h3 {
            margin: 0 0 20px 0 ;
            width: fit-content;
        }
    }
    ul.uliceLista {
        font-size: 12px;
        list-style: none;
        li {
            display: flex;
            padding: 5px 10px;
            border-radius: 5px;
            font-weight: initial;

            &.naglowek {
                border-bottom: 1px solid var(--ramka);
                border-radius: 0;
                &:hover {
                    background-color: initial;
                }
            }
            &.active {
                background-color: var(--podswietlenieListy)
            }
            div {
                flex: 1;
                justify-content: center;
                svg:last-child {
                    margin-left: 10px;
                }
                input {
                    display: block;
                    width: 30px;
                    margin-right: 5px;
                    padding: 3px 6px;
                    font-size: 11px;
                    &.ulica {
                        width: 95%;
                    }
                }

                input[type='number'] {
                    width: 70px;
                    &.pow {
                        width: 55px;
                    }

                }
            }
            &.g {
                background-color: #e9e1db;
            }
            &:hover {
                background-color: var(--podswietlenieListy)
            }
            
            &.lastRow {
                border-top: 1px solid var(--ramka);
                &:hover {
                    background-color: initial;
                }
            }
        }
    }

    div.wspolnotyEditForm {
        padding: 15px 10px;
        width: 500px;
        height: fit-content;
        background-color: var(--wnetrzeRamki);
    }
    div.wspolnotyLokale {
        padding: 15px 0 15px 10px;
        width: 450px;
        height: fit-content;
        background-color: var(--wnetrzeRamki);
        div.wspolnotyLokaleLista {
            max-height: 500px;
            overflow: auto;
            div.grupaLokali {
                div.naglowek {
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    margin: 5px 10px;
                    h4 {
                        display: block;
                        padding: 10px 15px;
                    }
                }
                ul.lokaleLista {

                    li {
                        &:first:hover {
                            background-color: initial;
                        }
                    }
                }
            }
        }
    }
    div.wspolnotyUlice {
        padding: 15px 10px;
        width: 350px;
        height: fit-content;
        background-color: var(--wnetrzeRamki);
        div.wspolnotyUliceDodaj {
            div {
                margin: 0 0 20px 0;
                p {
                    display: inline-block;
                    width: fit-content;
                }
                select, input {
                    display: inline-block;
                    margin: 0 0 0 15px;
                }
                input.nr {
                    width: 50px;    
                }
            }
        }

    }

}