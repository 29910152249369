@media (min-width: 320px) {
    div {
        &.login {
            display: flex;
            width: 100%;
            justify-content: center;
            section.cienie {
                margin: 50px auto;
                height: fit-content;
                padding: 50px 55px;
                background-color: white;
                h1 {
                    margin: 20px 0 40px 0;
                }
                form {
                    section {
                        &.user {
                            margin-bottom: 30px;
                        }
                        label {
                            border: 0;
                        }
                        input {
                            width: 200px;
                        }
                        div.pwd {
                            display: flex;
                            align-items: center;
                            input {
                                display: block;
                                width: 180px;
                            }
                        }
        
                    }
                    div.button {
                        width: 100%;
                        text-align: center;
                    }
                    span.warning {
                        display: block;
                        max-width: 175px;
                        font-weight: 700;
                        text-align: center;
                    }
                }
            }
        }   
    }
}
@media (min-width: 1150px) {
    div.login {
        transform: translateY(-200px);
    }
}