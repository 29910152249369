div.grupyLista {
    div.instrukcja {
        width: 60%;
        font-size: 13px;
        margin: 0 0 30px 30px;
        padding: 10px 15px;
        border: 1px solid var(--ramka);
        border-radius: 5px;
        line-height: 1.15rem;
        p {
            font-weight: 600;
        }
    }
    div.buttonsBar {
        display: flex;
        justify-content: space-between;
    }
    div.lista {
        font-size: 12px;
        margin-top: 30px;
        ul {
            list-style: none;
            li {
                display: flex;
                padding: 3px 0;
                // padding-bottom: 5px;
                border-bottom: 1px solid var(--ramka);
                width: fit-content;

                div.mieszkaniec {
                    padding: 0px 10px;
                    width: 400px;
                }
                div.udzialyGrupy {
                    width: fit-content;
                    div.udzialyRow {
                        display: flex;
                        width: fit-content;
                        flex-direction: column;
                        // margin-top: 5px;
                        &.border {
                            border-bottom: 1px solid var(--ramka);
                        }
                        div.udzialRow {
                            display: flex;
                            margin-bottom: 5px;
                            width: fit-content;
                            div {
                                width: 200px;
                            }
                        }
                        div.grupyRow {
                            display: flex;
                            
                            // padding: 0px 10px 5px 10px;
                            
                            div.grupaRow {
                                display: flex;
                                width: 100%; 
                                div {
                                    width: 200px;
                                }

                            }
                            &:not(:last-child) {
                                border-bottom: 1px solid gray;
                            }

                        }
    
                    }
                }

            }
        }
    }
}