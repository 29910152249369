div.zbiorowka {
    div.buttonsZbiorowka {
        display: flex;
        align-items: center;
        input {
            border-radius: 3px;
            overflow: hidden;
            margin: 10px 0 10px 20px;
        }
        svg {
            margin-left: 50px;
        }
    }
}