div.wspolnotaRozliczenie div.table-container {
  padding: 20px;
  border: 1px solid var(--ramka);
  border-radius: 5px;
  max-height: 500px;
  overflow: auto;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
}
div.wspolnotaRozliczenie div.container {
  display: flex;
  flex-direction: row;
}
div.wspolnotaRozliczenie div.container div.left {
  display: flex;
  flex-direction: column;
  width: 100%;
}
div.wspolnotaRozliczenie div.container div.left div.sms {
  display: flex;
  flex-direction: column;
  width: 500px;
}
div.wspolnotaRozliczenie div.container div.left div.sms input {
  display: block;
  margin: 5px 0;
  cursor: not-allowed;
}
div.wspolnotaRozliczenie div.container div.left div.sms button {
  width: 100px;
  cursor: not-allowed;
}
div.wspolnotaRozliczenie div.container div.left div.wspolnotaRozliczenieSaldo {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
div.wspolnotaRozliczenie div.container div.left div.wspolnotaRozliczenieSaldo div.tabelaNaleznosci {
  min-width: 500px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 20px;
  border: 1px solid var(--ramka);
  border-radius: 5px;
}
div.wspolnotaRozliczenie div.container div.left div.wspolnotaRozliczenieSaldo div.tabelaWplaty {
  min-width: 600px;
  max-height: 600px;
  overflow: auto;
  padding: 20px;
  border: 1px solid var(--ramka);
  border-radius: 5px;
}/*# sourceMappingURL=wspolnotaRozliczenie.css.map */