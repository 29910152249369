div.formDodajGrupe {
  padding: 20px;
  height: -moz-fit-content;
  height: fit-content;
}
div.formDodajGrupe p, div.formDodajGrupe h5 {
  margin: 20px 0 0 0;
}
div.formDodajGrupe input.red {
  border: 1px solid red;
  color: red;
}
div.formDodajGrupe div.listaBoxy {
  max-height: 300px;
  overflow: auto;
  padding: 2px 7px;
}
div.formDodajGrupe div.listaBoxy span.brak {
  font-size: 0.9rem;
}
div.formDodajGrupe div.listaBoxy div.checkbox {
  display: flex;
  width: 100%;
}
div.formDodajGrupe div.listaBoxy div.checkbox input, div.formDodajGrupe div.listaBoxy div.checkbox label {
  display: inline-block;
}
div.formDodajGrupe div.listaBoxy div.checkbox label {
  width: 100%;
  font-size: 0.9rem;
}/*# sourceMappingURL=dodajGrupe.css.map */