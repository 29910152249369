div.wspolnotyDodaj {
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  width: 500px;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--wnetrzeRamki);
}
div.wspolnotyDodaj div.wiersz {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}
div.wspolnotyDodaj div.wiersz select {
  width: -moz-fit-content;
  width: fit-content;
}
div.wspolnotyDodaj div.button {
  justify-content: center;
}

div.wspolnotyEdytuj {
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--wnetrzeRamki);
}
div.wspolnotyEdytuj div.wiersz {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}
div.wspolnotyEdytuj div.wiersz select {
  width: -moz-fit-content;
  width: fit-content;
}
div.wspolnotyEdytuj div.button {
  justify-content: center;
}

div.wspolnotyDane {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 30px 0 0 0;
}
div.wspolnotyDane div.naglowek {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px 0px 10px;
}
div.wspolnotyDane div.naglowek h3 {
  margin: 0 0 20px 0;
  width: -moz-fit-content;
  width: fit-content;
}
div.wspolnotyDane ul.uliceLista {
  font-size: 12px;
  list-style: none;
}
div.wspolnotyDane ul.uliceLista li {
  display: flex;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: initial;
}
div.wspolnotyDane ul.uliceLista li.naglowek {
  border-bottom: 1px solid var(--ramka);
  border-radius: 0;
}
div.wspolnotyDane ul.uliceLista li.naglowek:hover {
  background-color: initial;
}
div.wspolnotyDane ul.uliceLista li.active {
  background-color: var(--podswietlenieListy);
}
div.wspolnotyDane ul.uliceLista li div {
  flex: 1;
  justify-content: center;
}
div.wspolnotyDane ul.uliceLista li div svg:last-child {
  margin-left: 10px;
}
div.wspolnotyDane ul.uliceLista li div input {
  display: block;
  width: 30px;
  margin-right: 5px;
  padding: 3px 6px;
  font-size: 11px;
}
div.wspolnotyDane ul.uliceLista li div input.ulica {
  width: 95%;
}
div.wspolnotyDane ul.uliceLista li div input[type=number] {
  width: 70px;
}
div.wspolnotyDane ul.uliceLista li div input[type=number].pow {
  width: 55px;
}
div.wspolnotyDane ul.uliceLista li.g {
  background-color: #e9e1db;
}
div.wspolnotyDane ul.uliceLista li:hover {
  background-color: var(--podswietlenieListy);
}
div.wspolnotyDane ul.uliceLista li.lastRow {
  border-top: 1px solid var(--ramka);
}
div.wspolnotyDane ul.uliceLista li.lastRow:hover {
  background-color: initial;
}
div.wspolnotyDane div.wspolnotyEditForm {
  padding: 15px 10px;
  width: 500px;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--wnetrzeRamki);
}
div.wspolnotyDane div.wspolnotyLokale {
  padding: 15px 0 15px 10px;
  width: 450px;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--wnetrzeRamki);
}
div.wspolnotyDane div.wspolnotyLokale div.wspolnotyLokaleLista {
  max-height: 500px;
  overflow: auto;
}
div.wspolnotyDane div.wspolnotyLokale div.wspolnotyLokaleLista div.grupaLokali div.naglowek {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 5px 10px;
}
div.wspolnotyDane div.wspolnotyLokale div.wspolnotyLokaleLista div.grupaLokali div.naglowek h4 {
  display: block;
  padding: 10px 15px;
}
div.wspolnotyDane div.wspolnotyLokale div.wspolnotyLokaleLista div.grupaLokali ul.lokaleLista li:first:hover {
  background-color: initial;
}
div.wspolnotyDane div.wspolnotyUlice {
  padding: 15px 10px;
  width: 350px;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--wnetrzeRamki);
}
div.wspolnotyDane div.wspolnotyUlice div.wspolnotyUliceDodaj div {
  margin: 0 0 20px 0;
}
div.wspolnotyDane div.wspolnotyUlice div.wspolnotyUliceDodaj div p {
  display: inline-block;
  width: -moz-fit-content;
  width: fit-content;
}
div.wspolnotyDane div.wspolnotyUlice div.wspolnotyUliceDodaj div select, div.wspolnotyDane div.wspolnotyUlice div.wspolnotyUliceDodaj div input {
  display: inline-block;
  margin: 0 0 0 15px;
}
div.wspolnotyDane div.wspolnotyUlice div.wspolnotyUliceDodaj div input.nr {
  width: 50px;
}/*# sourceMappingURL=wspolnotyDane.css.map */