@charset "UTF-8";
@media (min-width: 320px) {
  div.glowna {
    display: flex;
    justify-content: center;
  }
  div.glowna div.text {
    width: 85%;
  }
  div.glowna div.text div.wstep {
    display: flex;
    align-items: stretch;
    background-color: white;
    margin: 50px 0 0 0;
  }
  div.glowna div.text div.wstep div.opis {
    width: 100%;
    padding: 20px 10px 20px 15px;
    height: auto;
  }
  div.glowna div.text div.wstep div.opis h3 {
    letter-spacing: 4px;
    text-transform: uppercase;
  }
  div.glowna div.text div.wstep div.opis div.separator {
    width: 110px;
    height: 5px;
    margin: 15px 0 30px 0;
    background-color: #f3bb97;
  }
  div.glowna div.text div.wstep div.opis p {
    margin: 5px 0;
    line-height: 1.7;
  }
  div.glowna div.text div.wstep div.opis p span.link {
    font-weight: 600;
    text-decoration: underline;
    line-height: 1.7;
    cursor: pointer;
  }
  div.glowna div.text div.wstep div.opis p a {
    text-decoration: underline;
  }
  div.glowna div.text div.s2 {
    margin-bottom: 100px;
  }
  div.glowna div.text div.wspolpraca {
    position: fixed;
    right: -100px; /* Ukryj początkowo element poza ekranem */
    transition: right 0.5s ease; /* Dodaj płynne przejście */
  }
  div.glowna div.text div.klienci {
    margin: 0 0 100px 0;
  }
  div.glowna div.text div.klienci h3 {
    text-transform: uppercase;
    font-weight: 500;
  }
  div.glowna div.text div.klienci div.opinie {
    display: flex;
    justify-content: space-around;
    margin: 30px 0 0 0;
  }
  div.glowna div.text div.klienci div.opinie div.opinia {
    width: 25%;
    min-height: 300px;
    border-radius: 5px;
    border: 1px solid var(--podswietlenie);
    background-color: white;
    padding: 50px 40px;
  }
  div.glowna div.text div.klienci div.opinie div.opinia span.tresc {
    font-style: oblique;
  }
  div.glowna div.text div.klienci div.opinie div.opinia p.autor {
    margin: 30px 0 0 0;
    font-style: oblique;
    text-align: right;
  }
}
@media (min-width: 1024px) {
  div.glowna div.text {
    width: 70%;
  }
  div.glowna div.text div.wstep {
    transform: translateY(-100px);
    margin: initial;
  }
  div.glowna div.text div.wstep div.opis {
    width: 65%;
    padding: 60px 40px 60px 45px;
  }
  div.glowna div.text div.s1 {
    margin-bottom: 150px;
  }
  div.glowna div.text div.s1 div.photo {
    height: auto;
    width: 35%;
    background-image: url("../../public/img/pexels-jonathan-borba-5570224 ORI (1).jpg");
    background-position: center;
    background-size: cover;
  }
  div.glowna div.text div.s2 {
    margin-bottom: initial;
  }
  div.glowna div.text div.s2 div.photo {
    height: auto;
    width: 35%;
    background-image: url("../../public/img/pexels-jonathan-borba-5570224 ORI (1).jpg");
    background-position: center;
    background-size: cover;
  }
  div.glowna div.text div.wspolpraca {
    position: fixed;
    right: -100px; /* Ukryj początkowo element poza ekranem */
    transition: right 0.5s ease; /* Dodaj płynne przejście */
  }
  div.glowna div.text div.klienci {
    margin: 0 0 100px 0;
  }
  div.glowna div.text div.klienci h3 {
    text-transform: uppercase;
    font-weight: 500;
  }
  div.glowna div.text div.klienci div.opinie {
    display: flex;
    justify-content: space-around;
    margin: 30px 0 0 0;
  }
  div.glowna div.text div.klienci div.opinie div.opinia {
    width: 25%;
    min-height: 300px;
    border-radius: 5px;
    border: 1px solid var(--podswietlenie);
    background-color: white;
    padding: 50px 40px;
  }
  div.glowna div.text div.klienci div.opinie div.opinia span.tresc {
    font-style: oblique;
  }
  div.glowna div.text div.klienci div.opinie div.opinia p.autor {
    margin: 30px 0 0 0;
    font-style: oblique;
    text-align: right;
  }
}/*# sourceMappingURL=glowna.css.map */