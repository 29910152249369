div.grupaWoda > div {
  margin: 0 0 15px 0;
}
div.grupaWoda > div div.naglowek {
  display: flex;
  margin: 0 0 5px 0;
}
div.grupaWoda > div div.naglowek h5 {
  width: -moz-fit-content;
  width: fit-content;
}
div.grupaWoda > div div.naglowek div {
  display: flex;
  margin: 0 10px;
}
div.grupaWoda > div div.naglowek div svg {
  margin: 0 0 0 20px;
}
div.grupaWoda > div div.form {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  font-size: 12px;
}
div.grupaWoda > div div.form div.left label, div.grupaWoda > div div.form div.left input, div.grupaWoda > div div.form div.right label, div.grupaWoda > div div.form div.right input {
  display: block;
  font-size: 12px;
}
div.grupaWoda > div div.form div.left label, div.grupaWoda > div div.form div.right label {
  text-align: left;
}
div.grupaWoda > div div.form div.left input, div.grupaWoda > div div.form div.right input {
  margin: 0 15px 0 0;
}
div.grupaWoda > div div.form div.left input[type=number], div.grupaWoda > div div.form div.left input[type=text], div.grupaWoda > div div.form div.right input[type=number], div.grupaWoda > div div.form div.right input[type=text] {
  width: 100px;
}
div.grupaWoda > div div.form div.left div.row, div.grupaWoda > div div.form div.right div.row {
  display: flex;
}
div.grupaWoda > div div.form div.right {
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
}
div.grupaWoda > div ul {
  font-size: 12px;
}
div.grupaWoda > div ul li {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
div.grupaWoda > div ul li.wiersz div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
div.grupaWoda > div.planpowaneZuzycie.rozliczenie div.dane {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}
div.grupaWoda > div.planpowaneZuzycie.rozliczenie div.dane div.tabele {
  display: flex;
  flex-direction: row;
}
div.grupaWoda > div.planpowaneZuzycie.rozliczenie div.dane div.tabele div.lewa, div.grupaWoda > div.planpowaneZuzycie.rozliczenie div.dane div.tabele div.prawa {
  width: 100%;
  margin: 0;
}
div.grupaWoda > div.planpowaneZuzycie.rozliczenie div.dane div.tabele div.lewa div.naglowek, div.grupaWoda > div.planpowaneZuzycie.rozliczenie div.dane div.tabele div.prawa div.naglowek {
  display: flex;
  margin: 10px 0;
  justify-content: center;
  font-weight: 500;
}
div.grupaWoda > div.planpowaneZuzycie.rozliczenie div.dane div.tabele div.lewa {
  display: flex;
  flex-direction: column;
}
div.grupaWoda > div.planpowaneZuzycie.rozliczenie div.dane div.tabele div.lewa table {
  text-align: center;
}
div.grupaWoda > div.planpowaneZuzycie.rozliczenie div.dane div.koszt {
  display: flex;
  flex-direction: column;
}
div.grupaWoda > div.planpowaneZuzycie.rozliczenie div.dane div.koszt div.naglowek {
  display: flex;
  margin: 10px 0;
  justify-content: center;
  font-weight: 500;
}
div.grupaWoda > div.planpowaneZuzycie.rozliczenie div.dane div.koszt div.kalkulacje p {
  margin: 0 0 5px 5px;
  text-align: right;
  padding: 0 20px 0 0;
}
div.grupaWoda > div.planpowaneZuzycie.rozliczenie div.dane div.koszt div.podsumowanie {
  display: flex;
  flex-direction: column;
}
div.grupaWoda > div.planpowaneZuzycie.rozliczenie div.dane div.koszt div.podsumowanie div {
  display: flex;
  width: 100%;
  justify-content: center;
}
div.grupaWoda > div.planpowaneZuzycie.rozliczenie div.dane div.koszt div.podsumowanie div table {
  margin-bottom: 5px;
}
div.grupaWoda > div.planpowaneZuzycie.rozliczenie div.dane div.suma {
  margin: 5px 10px;
}
div.grupaWoda > div.planpowaneZuzycie.rozliczenie div.dane div.suma p {
  text-align: center;
  font-weight: 600;
  margin: 10px;
}
div.grupaWoda > div.planpowaneZuzycie ul.odczyty li {
  display: flex;
  justify-content: space-between;
  padding: 0;
  width: 100%;
}
div.grupaWoda > div.planpowaneZuzycie ul.odczyty li div {
  flex: initial;
}
div.grupaWoda > div.planpowaneZuzycie ul.odczyty li div.w50 {
  width: 50px;
}
div.grupaWoda > div.planpowaneZuzycie ul.odczyty li div.w100 {
  width: 100px;
}

div.woda div.naglowek {
  justify-content: center;
}
div.woda div.naglowek.rozliczenie-daty {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: left;
}
div.woda div.naglowek div.listaWspolnot {
  padding: 40px 0;
}
div.woda div.naglowek div.cena {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
div.woda div.naglowek div.cena h4 {
  margin: 5px;
  width: 400px;
  text-align: center;
}
div.woda div.naglowek div.cena h4.c {
  border-left: 1px solid #e9ecef;
  border-top: 1px solid #e9ecef;
  border-right: 1px solid #e9ecef;
  padding: 15px 10px 0 10px;
}
div.woda div.naglowek div.cena h4.z {
  border-left: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  border-right: 1px solid #e9ecef;
  padding: 0px 10px 15px 10px;
}
div.woda div.container {
  display: flex;
  justify-content: space-between;
  margin: 10px 20px 0px 20px;
}
div.woda div.container div.listaMieszkancow {
  display: flex;
  width: 550px;
  max-height: 600px;
  overflow: auto;
}
div.woda div.container div.listaMieszkancow ul.lista {
  width: 100%;
  margin: 0 25px 0 0;
}
div.woda div.container div.listaMieszkancow ul.lista li {
  display: flex;
}
div.woda div.container div.listaMieszkancow ul.lista li div:first-child {
  flex: 0 0 60%;
}
div.woda div.container div.listaMieszkancow ul.lista li div:nth-child(2) {
  flex: 1;
}
div.woda div.container div.rightSide {
  display: flex;
  flex-direction: column;
  min-width: 40%;
  align-items: center;
  font-size: 14px;
}
div.woda div.container div.rightSide div.rozliczenie {
  display: flex;
  width: 100%;
}
div.woda div.container div.rightSide div.rozliczenie div.wodaRozliczenieWydruk div.modal div.A4 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
div.woda div.container div.rightSide div.rozliczenie div.wodaRozliczenieWydruk div.modal div.A4 section {
  display: flex;
}
div.woda div.container div.rightSide div.rozliczenie div.wodaRozliczenieWydruk div.modal div.A4 section.rozliczenie-wody-wydruk-nadawca-data {
  justify-content: space-between;
}
div.woda div.container div.rightSide div.rozliczenie div.wodaRozliczenieWydruk div.modal div.A4 section.rozliczenie-wody-wydruk-adresat {
  justify-content: flex-end;
  font-weight: 500;
}
div.woda div.container div.rightSide div.rozliczenie div.wodaRozliczenieWydruk div.modal div.A4 section.rozliczenie-wody-wydruk-naglowek {
  justify-content: center;
}
div.woda div.container div.rightSide div.rozliczenie div.wodaRozliczenieWydruk div.modal div.A4 section.rozliczenie-wody-wydruk-naglowek h4 {
  text-transform: uppercase;
}
div.woda div.container div.rightSide div.rozliczenie div.wodaRozliczenieWydruk div.modal div.A4 section.rozliczenie-wody-wydruk-tabele {
  flex-direction: row;
  justify-content: space-around;
}
div.woda div.container div.rightSide div.rozliczenie ul {
  list-style: none;
  width: 100%;
}
div.woda div.container div.rightSide div.rozliczenie ul li {
  display: flex;
  flex-direction: column;
}
div.woda div.container div.rightSide div.rozliczenie ul li div.rozliczenie-mieszkaniec-dane {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}
div.woda div.container div.rightSide div.rozliczenie ul li div.rozliczenie-mieszkaniec-dane div.rozliczenie-liczniki-stawki {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 15px 0;
}
div.woda div.container div.rightSide div.rozliczenie ul li div.rozliczenie-mieszkaniec-dane div.rozliczenie-liczniki-stawki div table {
  font-size: 11px;
}
div.woda div.container div.rightSide div.rozliczenie ul li div.rozliczenie-mieszkaniec-dane div.rozliczenie-liczniki-stawki div.rozliczenie-liczniki {
  margin-right: 15px;
}
div.woda div.container div.rightSide div.rozliczenie ul li div.rozliczenie-mieszkaniec-dane div.rozliczenie-liczniki-stawki div.rozliczenie-liczniki table tbody tr td:not(:first-child) {
  text-align: center;
}
div.woda div.container div.rightSide div.rozliczenie ul li div.rozliczenie-mieszkaniec-dane div.rozliczenie-liczniki-stawki div.rozliczenie-stawki table tbody tr td:not(:first-child) {
  text-align: right;
}
div.woda div.container div.rightSide div.rozliczenie ul li div.rozliczenie-mieszkaniec-dane div.rozliczenie-naliczenia-razem table {
  font-size: 11px;
}
div.woda div.container div.rightSide div.rozliczenie ul li div.rozliczenie-mieszkaniec-dane div.rozliczenie-naliczenia-razem table thead tr th {
  text-align: center;
}
div.woda div.container div.rightSide div.rozliczenie ul li div.rozliczenie-mieszkaniec-dane div.rozliczenie-naliczenia-razem table tbody tr td:not(:first-child) {
  text-align: right;
}
div.woda div.container div.rightSide div.rozliczenie ul li div.rozliczenie-mieszkaniec-dane div.rozliczenie-naliczenia-razem table tbody tr td.saldo {
  font-weight: 500;
}
div.woda div.container div.rightSide div.planowaneZuzycie {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}
div.woda div.container div.rightSide div.planowaneZuzycie div.naglowek {
  height: 75px;
}
div.woda div.container div.rightSide div.planowaneZuzycie div.naglowek h4 {
  margin: 0 25px 25px 25px;
}
div.woda div.container div.rightSide div.planowaneZuzycie div.formularz {
  display: flex;
  flex-direction: column;
  width: 80%;
}
div.woda div.container div.rightSide div.planowaneZuzycie div.formularz div.wiersz {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  margin: 5px 0;
}
div.woda div.container div.rightSide div.planowaneZuzycie div.formularz div.wiersz label {
  padding: 5px;
  width: 200px;
  font-size: 14px;
}
div.woda div.container div.rightSide div.planowaneZuzycie div.formularz div.wiersz div.input {
  display: flex;
  flex-direction: row;
  align-items: center;
}
div.woda div.container div.rightSide div.planowaneZuzycie div.formularz div.wiersz div.input input {
  width: 150px;
  margin: 0 10px 0 0;
}
div.woda div.container div.rightSide div.planowaneZuzycie div.formularz div.wiersz div.input span {
  width: 20px;
}
div.woda div.container div.rightSide div.planowaneZuzycie div.formularz div.wiersz.button {
  justify-content: center;
}
div.woda div.container div.rightSide div.planowaneZuzycie div.formularz div.wiersz.button button {
  width: -moz-fit-content;
  width: fit-content;
}
div.woda div.container div.rightSide div.planowaneZuzycie div.lista {
  display: flex;
  flex-direction: column;
  width: 80%;
}
div.woda div.container div.rightSide div.planowaneZuzycie div.lista ul li {
  display: flex;
  flex-direction: row;
  margin: 15px 0;
  padding: 5px 10px;
  border-radius: 5px;
}
div.woda div.container div.rightSide div.planowaneZuzycie div.lista ul li div.dane {
  display: flex;
  flex-direction: column;
  width: 100%;
}
div.woda div.container div.rightSide div.planowaneZuzycie div.lista ul li div.dane div.wierszData {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 0 0 5px 0;
}
div.woda div.container div.rightSide div.planowaneZuzycie div.lista ul li div.dane div.wierszData div.value {
  font-weight: 600;
}
div.woda div.container div.rightSide div.planowaneZuzycie div.lista ul li div.dane div.wierszZuzycia {
  display: flex;
  flex-direction: row;
}
div.woda div.container div.rightSide div.planowaneZuzycie div.lista ul li div.dane div.wierszZuzycia div {
  width: 50%;
}
div.woda div.container div.rightSide div.planowaneZuzycie div.lista ul li div.kosz {
  min-width: 20px;
  font-size: 20px;
}
div.woda div.container div.rightSide div.planowaneZuzycie div.lista ul li:hover {
  background-color: var(--podswietlenieListy);
}
div.woda div.container div.rightSide div.formularz {
  border: 1px solid #e9ecef;
  width: 400px;
}
div.woda div.container div.rightSide div.formularz label {
  font-size: 14px !important;
}
div.woda div.container div.rightSide div.formularz p {
  margin: 10px;
  text-decoration: underline;
}
div.woda div.container div.rightSide div.formularz div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3px 0;
}
div.woda div.container div.rightSide div.formularz div span {
  display: block;
}
div.woda div.container div.rightSide div.formularz input {
  width: 150px;
}
div.woda div.container div.rightSide div.formularz div.zuzycie .label {
  padding: 5px;
  width: 200px;
}
div.woda div.container div.rightSide div.formularz div.zuzycie .value {
  width: 20%;
}
div.woda div.container div.rightSide div.listaLicznikow {
  padding: 15px;
  border: 1px solid #e9ecef;
  width: 400px;
  font-size: 12px;
}
div.woda div.container div.rightSide div.listaLicznikow ul li {
  display: flex;
  flex-direction: column;
  border: 1px solid #e9ecef;
  padding: 5px 0;
}
div.woda div.container div.rightSide div.listaLicznikow ul li div.poczatek, div.woda div.container div.rightSide div.listaLicznikow ul li div.koniec {
  padding: 0px 10px;
  position: relative;
}
div.woda div.container div.rightSide div.listaLicznikow ul li div.poczatek div.data, div.woda div.container div.rightSide div.listaLicznikow ul li div.koniec div.data {
  padding: 5px 0;
}
div.woda div.container div.rightSide div.listaLicznikow ul li div.poczatek div.data svg, div.woda div.container div.rightSide div.listaLicznikow ul li div.koniec div.data svg {
  position: absolute;
  top: 10px;
  right: 10px;
}
div.woda div.container div.rightSide div.listaLicznikow ul li div.poczatek div.naglowek, div.woda div.container div.rightSide div.listaLicznikow ul li div.poczatek div.data, div.woda div.container div.rightSide div.listaLicznikow ul li div.poczatek div.stan, div.woda div.container div.rightSide div.listaLicznikow ul li div.koniec div.naglowek, div.woda div.container div.rightSide div.listaLicznikow ul li div.koniec div.data, div.woda div.container div.rightSide div.listaLicznikow ul li div.koniec div.stan {
  display: flex;
  width: 100%;
}
div.woda div.container div.rightSide div.listaLicznikow ul li div.poczatek div.naglowek div.opis, div.woda div.container div.rightSide div.listaLicznikow ul li div.poczatek div.data div.opis, div.woda div.container div.rightSide div.listaLicznikow ul li div.poczatek div.stan div.opis, div.woda div.container div.rightSide div.listaLicznikow ul li div.koniec div.naglowek div.opis, div.woda div.container div.rightSide div.listaLicznikow ul li div.koniec div.data div.opis, div.woda div.container div.rightSide div.listaLicznikow ul li div.koniec div.stan div.opis {
  width: 50%;
}
div.woda div.container div.rightSide div.listaLicznikow ul li div.poczatek div.naglowek div.value, div.woda div.container div.rightSide div.listaLicznikow ul li div.poczatek div.data div.value, div.woda div.container div.rightSide div.listaLicznikow ul li div.poczatek div.stan div.value, div.woda div.container div.rightSide div.listaLicznikow ul li div.koniec div.naglowek div.value, div.woda div.container div.rightSide div.listaLicznikow ul li div.koniec div.data div.value, div.woda div.container div.rightSide div.listaLicznikow ul li div.koniec div.stan div.value {
  width: 25%;
}
div.woda div.container div.rightSide div.listaLicznikow ul li div.poczatek div.naglowek, div.woda div.container div.rightSide div.listaLicznikow ul li div.koniec div.naglowek {
  margin: 1px 0 5px 0;
}
div.woda div.container div.rightSide div.listaLicznikow ul li div.stan.ciepla {
  padding-top: 5px;
}
div.woda div.container div.rightSide div.listaLicznikow ul li div.zuzycie {
  display: flex;
  padding: 0px 10px;
}
div.woda div.container div.rightSide div.listaLicznikow ul li div.zuzycie.ciepla {
  padding-top: 5px;
}
div.woda div.container div.rightSide div.listaLicznikow ul li div.zuzycie div.opis {
  width: 50%;
}
div.woda div.container div.rightSide div.listaLicznikow ul li div.zuzycie div.value {
  width: 25%;
}

div.mieszkaniecWoda {
  margin: 20px;
  display: flex;
  width: 100%;
  justify-content: space-around;
}
div.mieszkaniecWoda div.arch {
  width: 50%;
  padding-left: 50px;
}/*# sourceMappingURL=woda.css.map */