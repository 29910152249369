div.lokale {
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: fit-content;
    a {
        display: flex;
        flex-direction: column;
        align-items: left;
        ul {
            width: 100%;
            li {
                font-size: 13px;
            }
        }
        h5 {
            margin: 5px 0 5px 0; 
        }
    }    
}

div.mieszkancyRozliczenieSaldo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20%;
    margin-top: 10px;
    svg {
        width: fit-content;
        padding: 20px;
    }
    div.tabele {
        display: flex;
        justify-content: space-between;
        width: 100%;
        div.tabelaNaleznosci {
            width: 50%;
            max-height: 250px;
            overflow: auto;
        }
        div.tabelaWplaty {
            width: 30%;
            ul {
                max-height: 250px;
                overflow: auto;
            }
        }
    }
}