@media (min-width: 320px) {
  div.oNas {
    display: flex;
    justify-content: center;
  }
  div.oNas div.text {
    width: 100%;
  }
  div.oNas div.text div.misja {
    display: flex;
    align-items: stretch;
    background-color: white;
    z-index: 1;
    position: relative;
  }
  div.oNas div.text div.misja div.opis {
    width: 95%;
    padding: 60px 40px 60px 45px;
    height: auto;
  }
  div.oNas div.text div.misja div.opis h3 {
    letter-spacing: 4px;
  }
  div.oNas div.text div.misja div.opis div.separator {
    width: 110px;
    height: 3px;
    margin: 15px 0 30px 0;
    background-color: #f3bb97;
  }
  div.oNas div.text div.misja div.opis span {
    line-height: 1.7;
  }
  div.oNas div.text div.misja div.opis span p {
    margin: 7px 0;
  }
  div.oNas div.text div.misja div.opis span p a {
    text-decoration: underline;
  }
  div.oNas div.text div.oferta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
  }
  div.oNas div.text div.oferta h3 {
    font-size: 30px;
    font-weight: 700;
    width: -moz-fit-content;
    width: fit-content;
  }
  div.oNas div.text div.oferta div.separator {
    height: 5px;
    width: 110px;
    margin: 15px 0 30px 0;
    background-color: #f3bb97;
  }
  div.oNas div.text div.oferta span {
    text-align: center;
  }
  div.oNas div.text div.kategorie {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  div.oNas div.text div.kategorie div.kategoria {
    width: 85%;
    border: 1px solid var(--podswietlenie);
    background-color: white;
    padding: 20px 10px;
    margin: 15px 0;
    cursor: pointer;
    transition: transform 500ms;
  }
  div.oNas div.text div.kategorie div.kategoria div.ikona {
    text-align: center;
    margin: 5px 0 30px 0;
  }
  div.oNas div.text div.kategorie div.kategoria h3 {
    text-align: center;
    font-weight: 500;
    margin-bottom: 15px;
  }
  div.oNas div.text div.kategorie div.kategoria span {
    display: block;
    text-align: center;
  }
  div.oNas div.text div.opisKategorii {
    display: none;
  }
  div.oNas div.text div.ocena {
    margin: 30px 0 100px 0;
    display: flex;
    justify-content: center;
  }
  div.oNas div.text div.ocena div.text {
    text-align: center;
    width: 85%;
  }
  div.oNas div.text div.ocena div.text p {
    margin: 3px 0;
  }
  div.oNas div.text div.ocena div.text p span.link {
    font-weight: 600;
    text-decoration: underline;
    line-height: 1.7;
    cursor: pointer;
  }
}
@media (min-width: 1024px) {
  div.oNas div.text {
    width: 70%;
  }
  div.oNas div.text ul {
    list-style: initial !important;
    list-style-type: disc;
  }
  div.oNas div.text div.misja {
    transform: translateY(-100px);
  }
  div.oNas div.text div.misja div.opis {
    width: 65%;
  }
  div.oNas div.text div.misja div.photo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 35%;
    height: auto;
  }
  div.oNas div.text div.misja div.photo div {
    width: 250px;
    height: 45%;
    background-position: center;
    background-size: cover;
    border-radius: 5px;
  }
  div.oNas div.text div.misja div.photo div.p1 {
    background-image: url("../../public/img/Katarzyna_Stanek.jpg");
  }
  div.oNas div.text div.misja div.photo div.p2 {
    background-image: url("../../public/img/Aleksandra_Mojsiewicz.jpg");
  }
  div.oNas div.text div.oferta {
    margin: 0;
  }
  div.oNas div.text div.kategorie {
    flex-direction: row;
    margin: 75px 0 0 0;
    align-items: initial;
  }
  div.oNas div.text div.kategorie div.kategoria {
    width: 30%;
    padding: 50px 40px;
    cursor: pointer;
    transition: transform 500ms;
    margin: initial;
  }
  div.oNas div.text div.kategorie div.kategoria div.ikona {
    text-align: center;
    margin: 5px 0 30px 0;
  }
  div.oNas div.text div.kategorie div.kategoria h3 {
    text-align: center;
    font-weight: 500;
    margin-bottom: 15px;
  }
  div.oNas div.text div.kategorie div.kategoria span {
    display: block;
    text-align: center;
  }
  div.oNas div.text div.kategorie div.kategoria:hover {
    transform: scale(1.015);
  }
  div.oNas div.text div.kategorie div.kategoria.active {
    transform: scale(1.03);
  }
  div.oNas div.text div.opisKategorii {
    display: flex;
    margin: 50px 0 0 0;
  }
  div.oNas div.text div.opisKategorii div.img {
    width: 40%;
    border-radius: 5px;
    background-position: center;
    background-size: cover;
  }
  div.oNas div.text div.opisKategorii div.img.img1 {
    background-image: url("../../public/img/pexels-mikhail-nilov-8296949.jpg");
  }
  div.oNas div.text div.opisKategorii div.img.img2 {
    background-image: url("../../public/img/pexels-vlada-karpovich-4050312.jpg");
  }
  div.oNas div.text div.opisKategorii div.img.img3 {
    background-image: url("../../public/img/pexels-ksenia-chernaya-5767926.jpg");
  }
  div.oNas div.text div.opisKategorii div.text {
    width: 60%;
    padding: 20px 30px;
  }
  div.oNas div.text div.opisKategorii div.text h3 {
    line-height: 2.5;
  }
  div.oNas div.text div.opisKategorii div.text ul {
    line-height: 1.7;
  }
  div.oNas div.text div.opisKategorii div.text ul li {
    line-height: 1.7;
  }
  div.oNas div.text div.ocena {
    margin: 60px 0 0 0;
  }
  div.oNas div.text div.ocena div.text {
    width: 75%;
  }
}/*# sourceMappingURL=onas.css.map */