@media (min-width: 320px) {
  div.cookie-consent {
    position: fixed;
    bottom: 0;
    background-color: white;
    padding: 20px 10px;
    margin: 10px 5px;
  }
  div.cookie-consent a {
    text-decoration: underline;
  }
}/*# sourceMappingURL=cookie.css.map */