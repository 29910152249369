@media (min-width: 320px) {
    div.oNas {
        display: flex;
        justify-content: center;
        div.text {
            width: 100%;
            div.misja {
                display: flex;
                align-items: stretch;
                background-color: white;
                z-index: 1;
                position: relative;
                div.opis {
                    width: 95%;
                    padding: 60px 40px 60px 45px;
                    height: auto;
                    h3 {
                        letter-spacing: 4px;
                    }
                    div.separator {
                        width: 110px;
                        height: 3px;
                        margin: 15px 0 30px 0;
                        background-color: #f3bb97;
                    }
                    span {
                        line-height: 1.7;
                        p {
                            margin: 7px 0;
                            a {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
            div.oferta {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 50px 0;
                h3 {
                    font-size: 30px;
                    font-weight: 700;
                    width: fit-content;
                }
                div.separator {
                    height: 5px;
                    width: 110px;
                    margin: 15px 0 30px 0;
                    background-color: #f3bb97;
                }
                span {
                    text-align: center;
                }
            }
            div.kategorie {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                div.kategoria {
                    width: 85%;
                    border: 1px solid var(--podswietlenie);
                    background-color: white;
                    padding: 20px 10px;
                    margin: 15px 0;
                    cursor: pointer;
                    transition: transform 500ms;
                    div.ikona {
                        text-align: center;
                        margin: 5px 0 30px 0;
                    }
                    h3 {
                        text-align: center;
                        font-weight: 500;
                        margin-bottom: 15px;
                    }
                    span {
                        display: block;
                        text-align: center;
                    }
                }
            }
            div.opisKategorii {
                display: none;
            }
            div.ocena {
                margin: 30px 0 100px 0;
                
                display: flex;
                justify-content: center;
                div.text {
                    text-align: center;
                    width: 85%;
                    p {
                        margin: 3px 0;
                        span.link {
                            font-weight: 600;
                            text-decoration: underline;
                            line-height: 1.7;
                            cursor: pointer;
                        }
                    }
    
                }
            }
        }
    }
}

@media (min-width: 1024px) {
    div.oNas {
        
       
        div.text {
            width: 70%;
    
            ul {
                list-style: initial!important;
                list-style-type: disc;
                li {
                    // margin-left: 25px;
                }
            }
            div.misja {
                transform: translateY(-100px);
                div.opis {
                    width: 65%;
                }
                div.photo {
                    display: flex;
                    flex-direction: column;
                    justify-content:space-around;
                    align-items: center;

                    width: 35%;
                    div {
                        width: 250px;
                        height: 45%;
                        background-position: center;
                        background-size:cover;
                        border-radius: 5px;
                        &.p1 {
                            background-image: url("../../public/img/Katarzyna_Stanek.jpg");
                        }
                        &.p2 {
                            background-image: url("../../public/img/Aleksandra_Mojsiewicz.jpg");
                        }
                    }
                    height: auto;
                    
                    // background-image: url("../../public/img/tablica.jpg");

                }
            }
            div.oferta {
                margin: 0;
            }
            div.kategorie {
                flex-direction: row;
                margin: 75px 0 0 0 ;
                align-items: initial;
                div.kategoria {
                    width: 30%;
                    padding: 50px 40px;
                    cursor: pointer;
                    transition: transform 500ms;
                    margin: initial;
                    div.ikona {
                        text-align: center;
                        margin: 5px 0 30px 0;
                    }
                    h3 {
                        text-align: center;
                        font-weight: 500;
                        margin-bottom: 15px;
                    }
                    span {
                        display: block;
                        text-align: center;
                    }
                    &:hover {
                        transform: scale(1.015);
                    }
                    &.active {
                        transform: scale(1.03);
                    }
                }
            }
            div.opisKategorii {
                display: flex;
                margin: 50px 0 0 0 ;
                div.img {
                    width: 40%;
                    border-radius: 5px;
                    background-position: center;
                    background-size:cover;
                    &.img1 {
                        background-image: url("../../public/img/pexels-mikhail-nilov-8296949.jpg");
                    }
                    &.img2 {
                        background-image: url("../../public/img/pexels-vlada-karpovich-4050312.jpg");
                    }
                    &.img3 {
                        background-image: url("../../public/img/pexels-ksenia-chernaya-5767926.jpg");
                    }
                }
                div.text {
                    width: 60%;
                    padding: 20px 30px;
                    h3 {
                        line-height: 2.5;
                    }
                    ul {
                        line-height: 1.7;
                        li {
                            line-height: 1.7;
                        }
                    }
                }
            }
            div.ocena {
                margin: 60px 0 0 0;
                div.text {
                    width: 75%;
   
                }
            }
        }
    
    
    
    } 
}

