div.lokale {
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: -moz-fit-content;
  width: fit-content;
}
div.lokale a {
  display: flex;
  flex-direction: column;
  align-items: left;
}
div.lokale a ul {
  width: 100%;
}
div.lokale a ul li {
  font-size: 13px;
}
div.lokale a h5 {
  margin: 5px 0 5px 0;
}

div.mieszkancyRozliczenieSaldo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20%;
  margin-top: 10px;
}
div.mieszkancyRozliczenieSaldo svg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px;
}
div.mieszkancyRozliczenieSaldo div.tabele {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
div.mieszkancyRozliczenieSaldo div.tabele div.tabelaNaleznosci {
  width: 50%;
  max-height: 250px;
  overflow: auto;
}
div.mieszkancyRozliczenieSaldo div.tabele div.tabelaWplaty {
  width: 30%;
}
div.mieszkancyRozliczenieSaldo div.tabele div.tabelaWplaty ul {
  max-height: 250px;
  overflow: auto;
}/*# sourceMappingURL=mieszkancyRozliczenie.css.map */