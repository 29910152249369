@media (min-width: 320px) {
  div.content {
    width: 100%;
  }
  div.content div.komunikaty {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }
  div.content div.komunikaty div.komunikat {
    display: flex;
    flex-direction: column;
    width: 350px;
    min-height: 400px;
    padding: 10px 20px;
    margin: 25px 0;
    background-color: white;
  }
  div.content div.komunikaty div.komunikat div.naglowek {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  div.content div.komunikaty div.komunikat div.naglowek div.tytul {
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 25px;
    font-weight: 700;
    margin: 5px 5px;
  }
  div.content div.komunikaty div.komunikat div.naglowek div.data {
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 600;
    margin: 5px 5px;
  }
  div.content div.komunikaty div.komunikat div.tresc {
    line-height: 1.8em;
    padding: 5px 5px 15px 5px;
  }
  div.content div.komunikaty div.komunikat div.plikiLista p {
    font-size: 0.95rem;
    font-weight: 800;
  }
  div.content div.komunikaty div.komunikat div.plikiLista ul li {
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
    margin: 5px 10px;
  }
  div.content div.komunikaty div.komunikat div.plikiLista ul li div {
    width: -moz-fit-content;
    width: fit-content;
  }
  div.content div.komunikaty div.komunikat div.plikiLista ul li div span {
    margin-left: 5px;
    text-decoration: underline;
  }
  div.content div.komunikaty div.komunikat div.plikiLista ul li div.kosz {
    font-size: initial;
  }
  div.content div.komunikatFormularz {
    display: flex;
    flex-direction: column;
    width: -moz-fit-content;
    width: fit-content;
  }
  div.content div.komunikatFormularz input[type=text] {
    display: block;
    width: 100%;
  }
  div.content div.komunikatFormularz div.att {
    border-top: 1px solid var(--ramka);
    margin: 20px 0 10px 0;
  }
}
@media (min-width: 1024px) {
  div.content div.komunikaty {
    flex-direction: row;
    margin: 50px 0 0 50px;
  }
  div.content div.komunikaty div.komunikat {
    width: 350px;
    min-height: 400px;
    padding: 10px 20px;
    margin: 10px 25px;
  }
}/*# sourceMappingURL=komunikaty.css.map */