ul.wspolnotyLista li {
  display: flex;
  margin: 20px 10px;
}
ul.wspolnotyLista li:first-child {
  font-weight: 600;
}
ul.wspolnotyLista li div {
  width: 25%;
}

ul.wspolnoty {
  max-height: 500px;
  overflow: auto;
}
ul.wspolnoty li:first-child {
  font-weight: initial !important;
}

div.wyszukaj {
  display: flex;
  position: relative;
  align-items: center;
}
div.wyszukaj input.searchMieszkaniec {
  width: 600px;
  margin: 5px;
  padding: 5px;
  border: 1px solid gainsboro;
  font-size: 14px;
}
div.wyszukaj div.hintList {
  position: absolute;
  left: 40px;
  top: 33px;
  z-index: 10;
  width: 595px;
  max-height: 500px;
  overflow: auto;
}
div.wyszukaj div.hintList div.hint {
  padding: 5px;
  background-color: white;
  font-size: 10px;
  cursor: pointer;
  width: 100%;
}
div.wyszukaj div.hintList div.hint span {
  font-weight: 500;
}
div.wyszukaj div.hintList div.hint:hover {
  background-color: #e9ecef;
}

div.content div.ulice div.dodaj {
  display: flex;
  justify-content: space-around;
  margin: 30px 0;
}
div.content div.ulice div.dodaj div.lokal_dodaj {
  width: 33%;
  padding: 15px;
  border: 1px solid var(--podswietlenie);
  border-radius: 15px;
}
div.content div.ulice div.dodaj div.lokal_dodaj div.select {
  margin: 0 0 20px 0;
}
div.content div.ulice div.dodaj div.lokal_dodaj div.select p {
  display: inline-block;
  width: 200px;
}
div.content div.ulice div.dodaj div.lokal_dodaj div.input {
  margin: 0 0 20px 0;
}
div.content div.ulice div.dodaj div.lokal_dodaj div.input p {
  display: inline-block;
  width: 200px;
}
div.content div.ulice div.dodaj div.lokal_dodaj div.input input {
  display: inline-block;
  width: 100px;
}
div.content div.ulice div.listy {
  display: flex;
  padding: 20px 0;
  justify-content: space-around;
}
div.content div.ulice div.listy div.listaUlic {
  width: 33%;
}
div.content div.ulice div.listy div.listaUlic ul {
  cursor: pointer;
}
div.content div.ulice div.listy div.lokale {
  width: 33%;
}
div.content div.ulice div.listy div.lokale ul.scroll {
  max-height: 300px;
  overflow: auto;
}/*# sourceMappingURL=wspolnoty.css.map */