input[type='text'],
input[type='number'],
input[type='password'],
input[type='email'],
input[type='date'] {
    display: block;
    width: 250px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.43;
    color: #555;
    background-color: #fff;
    border: none;
    border-bottom: 1px solid #ccc;
    max-height: 33px;
}
input[type='date'] {
    width: 130px;
    font-size: 12px;
}

select {
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 12px;
}

input[type='number'], input[name='data_od'], input[name='data'] {
    width: 150px;
}

input[name='tresc'] {
    height: 30px;
}

.form-control {
    display: flex;
    margin-bottom: 5px;
    label.input, label.select {
        width: 200px;
    }
    label.kwota {
        width: 100px;
        margin-left: 30px;
    }
    label {
        &[for='woda_ciepla'], &[for='woda_zimna'], &[for='woda_data'] {
            width: 300px;
        }
    }
    
    
}
.error {
    color: red;
}

label {
    display: block;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 5px;
    // width: 20%;
    // border-bottom: 1px solid #ccc;

}

div.AddForm {
    label {
        width: 20%;
    }
    input {
        background-color: #e6f2ff;
        border-bottom: 1px solid #ccc;
    }
    
}

