div.glosowanie {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.glosowanie div.wspolnoty-lista {
  width: 100%;
}
div.glosowanie div.buttons {
  width: 100%;
}
div.glosowanie div.przeglad {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 50px;
}
div.glosowanie div.przeglad div.przegladListaSzczegoly {
  height: -moz-fit-content;
  height: fit-content;
  max-height: 500px;
  overflow: auto;
  padding: 10px;
}
div.glosowanie div.przeglad div.przegladListaSzczegoly div.ramka {
  padding: 10px 20px;
}
div.glosowanie div.przeglad div.przegladListaSzczegoly div.karta {
  width: 400px;
  margin: 0 0 20px 0;
}
div.glosowanie div.przeglad div.przegladListaSzczegoly div.karta.active {
  background-color: var(--wnetrzeRamkiHover);
  border-width: 2px;
}
div.glosowanie div.przeglad div.przegladListaSzczegoly div.karta span {
  font-size: 14px;
}
div.glosowanie div.przeglad div.przegladListaSzczegoly div.karta p.numer {
  font-weight: 500;
}
div.glosowanie div.przeglad div.przegladListaSzczegoly div.karta:hover {
  background-color: var(--wnetrzeRamkiHover);
}
div.glosowanie div.przeglad div.przegladListaSzczegoly div.szczegoly {
  width: 400px;
  margin: 0 0 20px 0;
}
div.glosowanie div.przeglad div.przegladListaSzczegoly div.szczegoly p.numer {
  text-decoration: underline;
  width: -moz-fit-content;
  width: fit-content;
}
div.glosowanie div.przeglad div.przegladListaSzczegoly div.szczegoly div.termin {
  font-size: 13px;
  margin: 10px 0;
  border-bottom: 1px solid var(--ramkaLight);
}
div.glosowanie div.przeglad div.przegladListaSzczegoly div.szczegoly.active {
  background-color: var(--wnetrzeRamkiHover);
  border-width: 2px;
}
div.glosowanie div.przeglad div.przegladListaSzczegoly div.szczegoly div.glosy {
  font-size: 13px;
  margin: 10px 0;
  border-bottom: 1px solid var(--ramkaLight);
}
div.glosowanie div.przeglad div.przegladListaSzczegoly div.glosy {
  margin: 0 0 20px 0;
  margin: 10px 0;
}
div.glosowanie div.przeglad div.przegladListaSzczegoly div.glosy.active {
  background-color: var(--wnetrzeRamkiHover);
  border-width: 2px;
}
div.glosowanie div.przeglad div.przegladListaSzczegoly div.glosy span {
  font-size: 14px;
}
div.glosowanie div.przeglad div.przegladListaSzczegoly div.szczegoly table, div.glosowanie div.przeglad div.przegladListaSzczegoly div.glosy table {
  border-collapse: collapse;
}
div.glosowanie div.przeglad div.przegladListaSzczegoly div.szczegoly table thead th, div.glosowanie div.przeglad div.przegladListaSzczegoly div.glosy table thead th {
  font-size: 11px;
  border: 1px solid;
  padding: 2px 3px;
}
div.glosowanie div.przeglad div.przegladListaSzczegoly div.szczegoly table tbody td, div.glosowanie div.przeglad div.przegladListaSzczegoly div.szczegoly table tfoot td, div.glosowanie div.przeglad div.przegladListaSzczegoly div.glosy table tbody td, div.glosowanie div.przeglad div.przegladListaSzczegoly div.glosy table tfoot td {
  font-size: 10px;
  border: 1px solid;
  padding: 2px 6px;
}
div.glosowanie form {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  width: -moz-fit-content;
  width: fit-content;
  padding: 30px 50px;
  border-radius: 5px;
  border: 1px solid var(--ramka);
}
div.glosowanie form div {
  width: -moz-fit-content;
  width: fit-content;
}
div.glosowanie form svg {
  margin-left: 10px;
}
div.glosowanie form h3 {
  margin-bottom: 20px;
}
div.glosowanie form button {
  width: -moz-fit-content;
  width: fit-content;
}
div.glosowanie form div.pytanie {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  max-width: 400px;
}
div.glosowanie form div.nowePytanie {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
div.glosowanie form div.nowePytanie div.tresc {
  display: flex;
  align-items: center;
}
div.glosowanie form div.nowePytanie div.tresc button {
  display: block;
}/*# sourceMappingURL=glosowanie.css.map */