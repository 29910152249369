div.zbiorowka div.buttonsZbiorowka {
  display: flex;
  align-items: center;
}
div.zbiorowka div.buttonsZbiorowka input {
  border-radius: 3px;
  overflow: hidden;
  margin: 10px 0 10px 20px;
}
div.zbiorowka div.buttonsZbiorowka svg {
  margin-left: 50px;
}/*# sourceMappingURL=zbiorowka.css.map */