div.wspolnotaRozliczenie {
    div.table-container {
        padding: 20px;
        border: 1px solid var(--ramka);
        border-radius: 5px;
        max-height: 500px;
        overflow: auto;
        width: fit-content;
        max-width: 100%;
    }
    div.container {
        display: flex;
        flex-direction: row;
        
        div.left {
            display: flex;
            flex-direction: column;
            width: 100%;
            div.sms {
                display: flex;
                flex-direction: column;
                width: 500px;
                input {
                    display: block; 
                    margin: 5px 0;
                    cursor: not-allowed;
                }
                button {
                    width: 100px;
                    cursor: not-allowed;
                }
            }
            div.wspolnotaRozliczenieSaldo {
                display: flex;
                justify-content: space-around;
                width: 100%;
                div.tabelaNaleznosci {
                    min-width: 500px;
                    height: fit-content;
                    padding: 20px;
                    border: 1px solid var(--ramka);
                    border-radius: 5px;
                }
                div.tabelaWplaty {
                    min-width: 600px;
                    max-height: 600px;
                    overflow: auto;
                    padding: 20px;
                    border: 1px solid var(--ramka);
                    border-radius: 5px;
                }
            }
        }
        div.right {
    
        }
    }


}