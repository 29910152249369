div.stawki {
  width: 100%;
}
div.stawki div.stawkiJednorazoweGrupy {
  display: flex;
  flex-direction: column;
  min-height: 600px;
}
div.stawki div.stawkiJednorazoweGrupy div.top {
  display: flex;
}
div.stawki div.stawkiJednorazoweGrupy div.top ul {
  display: block;
  height: -moz-fit-content;
  height: fit-content;
  min-width: 400px;
  max-height: 150px;
  overflow: auto;
}
div.stawki div.stawkiJednorazoweGrupy div.top ul li div {
  flex: initial;
}
div.stawki div.stawkiJednorazoweGrupy div.top ul li div:nth-of-type(1) {
  width: 100px;
}
div.stawki div.stawkiJednorazoweGrupy div.top ul li div:nth-of-type(2) {
  width: 300px;
}
div.stawki div.stawkiJednorazoweGrupy div.top ul li div:nth-of-type(3) {
  width: 100x;
}
div.stawki div.stawkiJednorazoweGrupy div.top div.wybor {
  display: flex;
}
div.stawki div.stawkiJednorazoweGrupy div.top div.wybor div {
  margin: 0 20px 5px 20px;
  width: -moz-fit-content;
  width: fit-content;
}
div.stawki div.stawkiJednorazoweGrupy div.top div.wybor div h4 {
  margin: 0 0 20px 0;
}
div.stawki div.stawkiJednorazoweGrupy div.stawkiJednorazoweGrupyLista ul {
  max-height: 400px;
  overflow: auto;
}
div.stawki div.stawkiJednorazoweGrupy h3 {
  margin: 20px 0 20px 0;
}
div.stawki div.stawkiJednorazoweGrupy div.stawkaForm {
  display: flex;
  flex-direction: column;
}
div.stawki div.stawkiJednorazoweGrupy div.stawkaForm div.wiersz {
  margin: 0 0 20px 0;
}
div.stawki div.stawkiJednorazoweGrupy div.stawkaForm div.wiersz label {
  display: block;
  width: 100px;
}
div.stawki h1.nazwa {
  margin: 0 0 10px 0;
}
div.stawki div.stawkiContainer {
  display: flex;
  flex-direction: column;
}
div.stawki div.stawkiContainer h3 {
  margin: 20px 0 20px 0;
}
div.stawki div.stawkiContainer div.listy {
  display: flex;
  flex-direction: row;
}
div.stawki div.stawkiContainer div.listy div.lista {
  width: 50%;
}
div.stawki div.stawkiContainer div.listy div.lista ul {
  margin: 0 0 20px 0;
}
div.stawki div.stawkiContainer div.listy div.lista ul li div.klucz {
  width: 50%;
}
div.stawki div.stawkiContainer div.listy div.lista ul li div.wartosc {
  width: 15%;
}
div.stawki div.stawkiContainer div.listy div.arch {
  flex: 1;
}
div.stawki div.stawkiContainer div.listy div.arch ul.listaArch {
  width: 25%;
}
div.stawki div.stawkiContainer div.listy div.arch ul.listaArch li {
  display: flex;
  justify-content: space-between;
  padding: 3px 5px;
  margin: 2px;
}
div.stawki div.stawkiContainer div.listy div.arch ul.listaArch li:hover {
  background-color: #e0d6cf;
}
div.stawki div.stawkiContainer div.listy div.arch ul.listaArch li div.icon {
  margin: 0 5px;
}
div.stawki div.stawkiContainer div.formularz {
  margin: 20px;
  width: 80%;
}
div.stawki div.stawkiContainer div.formularz div.wiersz {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
}
div.stawki div.stawkiContainer div.formularz div.wiersz div.check {
  width: 33%;
}
div.stawki div.stawkiContainer div.formularz div.wiersz div.check input {
  margin-right: 10px;
}
div.stawki div.stawkiContainer div.formularz div.wiersz div.input {
  width: 33%;
}
div.stawki div.stawkiContainer div.formularz div.wiersz div.lista {
  width: 33%;
}
div.stawki div.stawkiContainer div.formularz div.button {
  display: flex;
  justify-content: center;
}/*# sourceMappingURL=stawki.css.map */