@media (min-width: 320px) {
    footer {
        background-color: var(--tlo);
        padding: 15px 0 0 0 ;
        div.content {
            padding: initial;
            display: flex;
            flex-direction: column;
            div.contact {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                // margin: 0 10px;
                background-color: #e3d7cf;
                border-radius: 10px;
                padding: 10px 15px;
                margin: 0;
                
                div {
                    flex: 1;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 0;
                    p {
                        margin: 15px 0;
                        padding: 0 5px;
                        &.head {
                            font-weight: 400;
                            text-transform: uppercase;
                            margin-bottom: 20px;
                        }
                    }
                    &.c1 {
                        div.logo {
                            text-align: center;
                            img {
                                height: auto;
                                width: 250px;
                            }
                        }
                        div.socials {
                            display: flex;
                            flex-direction: row;
                            text-align: center;
                            margin-top: 15px;
                            a {
                                display: block;
                                svg {
                                    margin: 10px;
                                }
                            }

                        }
                    }
                    &.c2, &.c3 {
                        div {
                            display: flex;
                            flex-direction: row;
                            width: 100%;
                            p {
                                display: block;
                                font-size: 0.9rem;
                                width: 50%;
                                margin: 5px 0;
                                text-wrap: nowrap;
                                &:first-child {
                                    font-weight: 600;
                                }
                                &:last-child {
                                    text-align: right;
                                }
                            }
                        }
                    }
                    &.c4 {
                        p {
                            font-size: 0.9rem;
                            margin: 5px 0;
                            display: block;
                            &.head {
                                font-weight: 400;
                                text-transform: uppercase;
                                margin: 15px 0;
                                margin-bottom: 20px;
                                font-size: initial;
                            }
                            &.brand {
                                font-size: 0.9rem;
                                margin: 5px 0;
                                font-weight: 600;
                            }
    
                        }
                        div {
                            margin-top: 15px;
                        }
                    }
                }
            }
            div.singature {
                padding: 20px 0;
                p {
                    color: #c2bab9;
                    text-align: center!important;
                    margin: 10px;
                    font-size: .8rem;
                }
                
            }
        }
    }
}

@media (min-width: 1024px) {
    footer {
        padding: 50px 0 0 0 ;
        div.content {
            div.contact {
                flex-direction: row;
                align-items: initial;
                margin: 0 30px;
                border-radius: 30px;
                padding: 30px 40px;
                div {
                    p {
                        padding: 0 35px;
                    }
                }
            }
            div.singature {
                padding: 20px 0;
                p {
                    color: #c2bab9;
                    text-align: center!important;
                    margin: 10px;
                    font-size: .8rem;
                }
                
            }
        }
    }
}
