div.noty {
    display: flex;
    flex-direction: column;
    width: 100%;
    div.notyContainer {
        display: flex;
        div.lista {
            width: 45%;
            padding: 50px 0 0 0 ;
            h4 {
                margin-bottom: 20px;
            }
            table.noty {
                font-size: 10px;
                width: fit-content;
                margin-bottom: 20px;
                tr {
                    td, th {
                        border-collapse: collapse;
                        border: 1px solid;
                        padding: 3px 10px;
                    }
                }
    
            }
            &.historia {
                width: 100%;
            }
        }
        div.preview {
            display: flex;
            justify-content: center;
            width: 55%;
            div.previewDock {
                // display: none;
            }
        }
    }
}
