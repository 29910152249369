div.content.logi {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    h3 {
        margin: 15px 0;
        width: 100%;
    }
    div.filtrBox {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 5px 5px;
        div.box {
            display: flex;
            flex-direction: row;
            margin: 10px 0;
            width: 100%;
            height: fit-content;
            flex-wrap: wrap;
            div.filtrBtn {
                padding: 5px 10px;
                font-size: 12px;
                width: fit-content;
                background-color: var(--podswietlenieListy);
                border-radius: 5px;
                margin: 0 5px 3px 0;
                &.active {
                    background-color: var(--fontAwesome);
                    color: var(--tlo);
                }
            }
        }
    }
    div.table {
        width: fit-content;
        max-height: 350px;
        overflow: auto;
        table.logi {
            width: fit-content;
            font-size: 11px;

            thead {
                tr {
                    text-align: right;
                    th {
                        text-align: center;
                        padding: 5px 10px;
                    }
                }
            }
            tbody {
                tr {
                    text-align: right;
                    &:hover {
                        background-color: var(--podswietlenieListy)
                    }
                    td {
                        text-align: center;
                        padding: 5px 10px;
                    }
                }
            }
        }
    }
}
