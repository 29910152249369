div.serwis {
    width: 800px;
    div.formularz {
        input[type="text"] {
            width: 100%;
        }
        span {
            font-size: 13px;
        }
    }
    div.historia {
        ul {
            li {
                display: flex;
                div {
                    flex: initial;
                    width: 150px;
                    &:nth-of-type(1) {
                        width: 40px;
                    }
                    &:nth-of-type(2) {
                        flex-grow: 1;
                    }
                    &:nth-of-type(3), &:nth-of-type(4) {
                        text-align: center;
                    }
                    &:last-of-type {
                        width: 30px;
                        text-align: center;
                    }
                }
            }
        }
    }
}