div.grupyLista div.instrukcja {
  width: 60%;
  font-size: 13px;
  margin: 0 0 30px 30px;
  padding: 10px 15px;
  border: 1px solid var(--ramka);
  border-radius: 5px;
  line-height: 1.15rem;
}
div.grupyLista div.instrukcja p {
  font-weight: 600;
}
div.grupyLista div.buttonsBar {
  display: flex;
  justify-content: space-between;
}
div.grupyLista div.lista {
  font-size: 12px;
  margin-top: 30px;
}
div.grupyLista div.lista ul {
  list-style: none;
}
div.grupyLista div.lista ul li {
  display: flex;
  padding: 3px 0;
  border-bottom: 1px solid var(--ramka);
  width: -moz-fit-content;
  width: fit-content;
}
div.grupyLista div.lista ul li div.mieszkaniec {
  padding: 0px 10px;
  width: 400px;
}
div.grupyLista div.lista ul li div.udzialyGrupy {
  width: -moz-fit-content;
  width: fit-content;
}
div.grupyLista div.lista ul li div.udzialyGrupy div.udzialyRow {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  flex-direction: column;
}
div.grupyLista div.lista ul li div.udzialyGrupy div.udzialyRow.border {
  border-bottom: 1px solid var(--ramka);
}
div.grupyLista div.lista ul li div.udzialyGrupy div.udzialyRow div.udzialRow {
  display: flex;
  margin-bottom: 5px;
  width: -moz-fit-content;
  width: fit-content;
}
div.grupyLista div.lista ul li div.udzialyGrupy div.udzialyRow div.udzialRow div {
  width: 200px;
}
div.grupyLista div.lista ul li div.udzialyGrupy div.udzialyRow div.grupyRow {
  display: flex;
}
div.grupyLista div.lista ul li div.udzialyGrupy div.udzialyRow div.grupyRow div.grupaRow {
  display: flex;
  width: 100%;
}
div.grupyLista div.lista ul li div.udzialyGrupy div.udzialyRow div.grupyRow div.grupaRow div {
  width: 200px;
}
div.grupyLista div.lista ul li div.udzialyGrupy div.udzialyRow div.grupyRow:not(:last-child) {
  border-bottom: 1px solid gray;
}/*# sourceMappingURL=grupy.css.map */