@media (min-width: 320px) {
    div.mieszkaniec {
        display: flex;
        flex-direction: column;
        width: 100%;
        div.buttons {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            button {
                margin: 5px 10px;
                &.raport {
                    display: none;
                }
            }
        }
        div.content {
            padding: 0;
            div.mieszkaniecDaneContainer {
                display: flex;
                flex-direction: column;
                max-width: 480px;
                div.lista { 
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: fit-content;
                    padding: 10px 15px;
                    border-radius: 10px;
                    background-color: var(--wnetrzeRamki);
                    transition: background-color 0.5s;
                    margin: 0px 0 10px 0;
                    font-size: 0.9rem;
                    h3 {
                        font-size: 1.1rem;
                    }
                    &:hover {
                        background-color: var(--wnetrzeRamkiHover);     
                    }
                    div {
                        width: fit-content;
                        width: 100%;
                        ul li {
                            display: flex;
                            justify-content: space-between;
                            margin: 5px 0;
                            border: initial;
                            font-weight: initial;
                            div {
                                flex: initial;
                                &:first-child {
                                    width: 100px;
                                }  
                                &:last-child {
                                    width: fit-content;
                                }
                            }
                        }
                    }
                }
                div.grupy {
                    display: flex;
                    flex-direction: column;
                    // max-height: 600px;
                    overflow: auto;
                    div.grupyLista {
                        div.grupa {
                            padding: 5px 10px;
                            border-radius: 10px;
                            background-color: var(--wnetrzeRamki);
                            transition: background-color 0.5s;
                            margin: 0 0 10px 0;
                            cursor: pointer;
                            .hover {
                                &:hover {
                                    background-color: var(--wnetrzeRamkiHover);     
                                }
                            }
                            div.naglowek {
                                h3 {
                                    padding: 0 0 5px 0;
                                }
                                h5 {
                                    padding: 15px;
                                    width: fit-content;
                                }
                            }
                            ul.lista.udzialKonto {
                                list-style: none;
                                li {
                                    font-size: 12px;
                                    padding: 0;
                                    &:first-child, &:last-child {
                                        display: flex;
                                    }
                                    div {
                                        display: flex;
                                        align-items: center;
                                        &:first-child {
                                            margin: 0 15px 0 0;
                                        }
                                        span {
                                            display: block;
                                            width: fit-content;
                                            padding: 5px 5px 5px 0;
                                        }
                                        svg {
                                            padding: 5px 0;
                                        }
                                        &.numer {
                                            padding: 0 3px;
                                            border-radius: 3px;
                                            &:hover {
                                                background-color: var(--podswietlenieListy);
                                            }
                                        }
                                    }
                                }
                            }
                            div.lokale {
                                display: flex;
                                flex-direction: column;
                                padding: 0;
                                width: 100%;
                                h4 {
                                    margin: 0 0 5px 0;
                                }
                                ul {
                                    list-style: none;
                                    li {
                                        display: flex;
                                        margin: 0;
                                        font-size: 12px;
                                        font-weight: initial;
                                        border: initial;
                                        padding: 2px 5px;
                                        div {
                                            flex: initial;
                                            &:first-child {
                                                width: 150px;
                                            }
                                        }
                                    }
                                }
                                h5 {
                                    margin: 5px 0 5px 0; 
                                }
                            }
                        }
                    }
                }   
        
            }
        }
        h4 {
            // padding: 5px 0;
            width: fit-content;
        }
        div.usterka {
            display: flex;
            align-items: center;
            flex-direction: column;

            form {
                width: fit-content;
                div {
                    margin-top: 30px;
                    select {
                        min-width: 165px;
                    }
                }
            }
        }
        div.glosowanie {
            div.lista-kart {
                width: 95%;
                padding: 10px;
                margin: 0 0 10px 0;
                div.lista-aktywne, div.lista-archiwum {
                    margin: 5px 0 10px 0;
                }
            }
        }
        div.rozliczeniaContainer {
            display: flex;
            flex-direction: column;
            // width: 800px;
            height: fit-content;
            background-color: var(--wnetrzeRamki);
            padding: 10px 15px;
            h3 {
                font-size: 15px;
                font-weight: 600;
            }
            div.stawkiAkt {
                display: flex;
                flex-direction: column;
                padding: 5px 0px!important;
                div.naglowek {
                    display: flex;
                    justify-content: space-between;
                    font-size: 14px;
                    svg {
                        display: none;
                    }
                }
                ul {
                    font-size: 12px;
                    li {
                        display: flex;
                        justify-content: space-between;
                        padding: 2px;
                        border-radius: 5px;
                        // width: 75%;
                        &.firstRow {
                            font-weight: 600;
                            border-bottom: 1px solid var(--ramka);
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                            &:hover {
                                background-color: initial;
                            }
                        }
                        &.lastRow {
                            font-weight: 600;
                            border-top: 1px solid var(--ramka);
                            border-top-left-radius: 0;
                            border-top-right-radius: 0;
                            &:hover {
                                background-color: initial;
                            }
                            div:last-child {
                                width: 70px;
                            }
                        }
                        &:hover {
                            background-color: var(--podswietlenie);
                        }
                        div {
                            width: 50px;
                            align-content: center;
                            
                            &:nth-child(1) {
                                width: 130px;
                            }
                            
                            &:not(:first-child) {
                                text-align: right;
                            }
                        }
                    }
                }
            }
            div.tabele {
                display: flex;
                flex-direction: column;
                justify-content: center;
                div {
                    width: 100%;
                    margin: 0 0 20px 0;
                    max-height: 200px;
                    overflow: auto;
                    &:first-child {
                        overflow-x: auto;
                    }
                    h4 {
                        margin: 5px 0;
                    }
                    table {
                        font-size: 12px;
                        border-collapse: collapse;
                        width: 100%;
                        th {
                            font-size: 12px;
                            font-weight: 500;
                            text-align: center;
                            padding: 3px;
                            border-bottom: 1px solid var(--ramka);
                        }
                        tbody {

                            td {
                                white-space: nowrap;
                                padding: 5px 10px;
                                word-wrap: break-word;
                                &:last-child {
                                    font-weight: 600;
                                }
                            }
                        }

                    }
                }
    
    
            }
            div.saldo {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 5px 0; 
                span {
                    font-size: 14px;
                }
            }
        }
    
    }
}

@media (min-width: 1024px) {
    div.mieszkaniec {
        flex-direction: column;
        align-items: center;
        padding: 20px 0 0 0 ;
        div.buttons {
            margin-bottom: 20px;
            width: 100%;
            button.raport {
                display: block;
            }
        }
        div.content {

        }
        h4 {
            padding: 10px 0;
        }
        div.glosowanie {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            width: 100%;
            justify-content: space-around;

            div.lista-kart {
                max-height: 500px;
                overflow: auto;
                padding: 10px;
                div.lista-aktywne, div.lista-archiwum {
                    div.kartaNaglowek {
                        padding: 10px 20px;
                        background-color: var(--tlo);
                        border: 1px solid var(--fontAwesome);
                        border-radius: 5px;
                        font-size: 14px;
                        cursor: pointer;
                        transition: background-color 0.15s ease;
                        &:hover {
                            background-color: var(--wnetrzeRamkiHover);
                        }
                        &.active {
                            background-color: var(--wnetrzeRamkiHover);
                            border-width: 2px;
                        }
                    }
                }
                div.lista-archiwum {
                    margin-top: 50px;
                }


            }
            div.karta {
                width: 400px;
                margin: 0 0 20px 0;
                p.header {
                    text-decoration: underline;
                }
                p.data {
                    font-size: 15px;
                    margin: 15px 0;
                }
                section.pytania {
                    margin: 20px 0;
                    font-size: 15px;
                    div.pytanie {
                        display: flex;
                        justify-content: space-between;
                        margin: 10px 0;                            
                        border-bottom: 1px solid var(--ramka);
                        div.tresc {
                            display: flex;
                            align-items: center;
                            
                        }
                    }
                }
            }
        }
        div.content {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            &.wspolnotaRozliczenie {
                flex-direction: column;
            }
            select {
                width: fit-content;
                margin: 15px 0;
            }
            div.mieszkaniecDaneContainer {
                max-width: 400px;
                div.lista { 
                    div {
                        ul {
                            li {
                                margin: 10px 0 5px 0;
                            }
                        }
                    }
                }
                div.grupy {
                    div.grupyLista {
                        div.grupa {
                            ul {
                                li {
                                    div:first-child {
                                        width: 130px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            div.rozliczeniaContainer {
                width: 700px;
                max-height: 810px;
                overflow: auto;

                div.stawkiAkt {
                    padding: 0 0 0 20px;
                    div.naglowek {
                        svg {
                            display: block;
                        }
                    }
                    div.mieszkaniecWydruk {
                        position: fixed; /* Ustawiamy tło jako fixed, aby zajmowało cały ekran */
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%; /* 100% wysokości widoku przeglądarki */
                        background-color: rgba(0, 0, 0, 0.5); /* Półprzezroczyste tło */
                        z-index: 1; /* Tło powinno być poniżej modala */
                        display: flex;
                        justify-content: center;
                        overflow: auto;
                        div.modal {
                            background-color: white;
                            margin: 50px 0;
                            padding: 20px;
                            height: fit-content;
                            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                            z-index: 10; /* Modal powinien być nad tłem */
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            width: fit-content;
                            box-sizing: border-box; /* Uwzględnia padding w wymiarach elementu */
                        }
                    }
                    ul {
                        li {
                            width: 100%;
                        }
                    }
                }
                div.tabele {
                    padding: 0 20px;  
                }
                div.saldo {
                    padding: 20px 20px;
                }
            }
        }
        div.serwis {
            width: 100%;
            p {
                text-align: center;
                margin: 20px;
            }
            
        }

        div.glosowanie {
            div.lista-kart {
                width: 500px;
            }
        }


    
    }
}

@media (min-width: 1280px) {
    div.mieszkaniec {
        padding: 20px 100px 0 100px;
    }
}

div.mieszkancy {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    div.left {
        display: flex;
        flex-direction: column;
        div.dane {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            div.content {
                div.addForm {
                    display: flex;
                    flex-direction: column;
                    width: 500px;
                    
                    div.wiersz {
                        justify-content: space-between;
                        display: flex;
                        margin: 3px 0;
                        span, input {
                            display: block;
                        }
                    }
                }
            }
            div.listaMieszkancow {
                width: 550px;
                div.mieszkancyUlice {
                    display: flex;
                    flex-direction: column;
                    div.box {
                        display: flex;
                        flex-direction: row;
                        margin: 10px 0;
                        width: 100%;
                        height: fit-content;
                        flex-wrap: wrap;
                        div.ulicaBtn {
                            padding: 5px 10px;
                            font-size: 12px;
                            width: fit-content;
                            background-color: var(--podswietlenieListy);
                            border-radius: 5px;
                            margin: 0 5px 3px 0;
                            &.active {
                                background-color: var(--fontAwesome);
                                color: var(--tlo);
                            }
                        }
                    }
                }
                ul.lista {
                    width: 100%;
                    font-size: 11px;
                    max-height: 350px;
                    overflow: auto;
                    border-radius: 5px;
                }
            }  
    
        }
    }
    div.mieszkancyDane {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: fit-content;
        min-width: 700px;
        padding: 10px 10px 10px 0;
        max-height: 700px;
        overflow: auto;
        svg {
            margin-left: 10px;
        }
        div.button {
            button {
                width: fit-content;
            }
        }
        div.lewa, div.prawa {
            margin: 0 10px ;
        }
        div.lewa {
            display: flex;
            flex-direction: column;
            max-width: 50%;
            div.ramka {
                ul.mieszkancyDane {
                    
                    li {
                        width: 100%;

                        div {
                            // width: 50%;
                            flex: initial;
                            display: flex;
                            align-items: center;
                            
                            input[type=text] {
                                width: 150px;
                                font-size: 12px;
                            }
                            input[type=date] {
                                margin-left: 15px;
                            }
                            &.pwd {
                                flex-grow: 1;
                                display: flex;
                                justify-content: space-between;
                            }
                        }
                        >div:first-child {
                            width: 150px;
                        }
                    }
                }
            }

            div.saldo {
                font-size: 12px;
                h2 {
                    margin-bottom: 10px;
                }
                p {
                    margin: 10px 0;
                }
            }
            div.naleznosci {
                div.naleznosci {
                    table {
                        font-size: 12px;
                        width: 100%;
                        thead {
                            text-align: left;
                        }
                    }
                }
            }
            div.mieszkancyHistoria {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 10px 10px;
                font-size: 0.8rem;
                border: solid 1px var(--ramka);
                border-radius: 5px;
                div.data {
                    font-weight: 800;
                    font-size: 0.7rem;
                    padding-bottom: 5px;
                }
                div.wiersz {
                    display: flex;
                    flex-direction: row;
                    padding-bottom: 5px;
                    div {
                        width: 50%;
                    }
                }
            }
            div.historia-wplat {
                h2 {
                    margin-bottom: 10px;
                }
                ul.historiaWplat {
                    font-size: 12px;
                    li {
                        display: flex;
                        justify-content: space-between;
                        padding: 0px 10px;
                        width: 100%;
                        &:hover {
                            background-color: var(--podswietlenie);
                        }
                    }
                    span.brakWplat {
                        display: block;
                        margin: 5px;
                    }
                }
            }
        }   
        div.prawa {
            div.stawkiInd {
                font-size: 12px;
                div.wsad {
                    display: flex;
                    div.form {
                        width: 100%;
                        ul.lista {
                            
                            li {
                                padding: 2px 0;
                                div {
                                    input {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                    div.daty {
                        display: flex;
                        width: 25%;
                        ul {
                            width: 100%;
                        }
                    }
                    div.stawki {
                        display: flex;
                        width: 75%;
                        ul {
                            width: 100%;
                            li {
                                &:hover {
                                    background-color: initial;
                                }
                            }
                        }
                    }
                }
            }
            div.grupaUdzial {
                min-width: 500px;
                div.naglowek {
                    display: flex;
                    justify-content: space-between;
                }
                ul.daneGrupy {
                    padding: 0 0 5px 0;
                    width: 100%;
                    font-size: 12px;
                    li {
                        display: flex;
                        flex: initial;
                        padding: 5px 0 0 0;
                        align-items: center;
                        div.w150 {
                            width: 170px;
                            font-weight: 600;
                            align-content: center;
                        }
                        svg {
                            margin-left: 20px;
                            display: block;
                            align-content: center;
                        }
                        div:last-child {
                            font-weight: initial;
                        }
                        &:not(:first-child):hover {
                            background-color:initial;
                        }
                    }
                }
                div.historia {
                    display: flex;
                    flex-direction: column;
                    padding: 5px 15px 0 15px;
                    font-size: 12px;
                    
                    border-radius: 5px;
                    h2 {
                        margin: 0px 0px 10px 20px;
                    }
                    div.zmiana {
                        border: solid 1px var(--ramka);
                        border-radius: 5px;
                        max-height: 300px;
                        overflow: auto;
                        ul.historiaZmian {
                            list-style: none;
                            li {
                                display: flex;
                                padding: 5px 0;
                                width: 100%;
                                &:hover {
                                    background-color: var(--podswietlenie);
                                }
                                div {
                                    margin: 1px 0px;
                                    &.kosz-con {
                                        display: flex;
                                        justify-content: center; /* Wypośrodkowanie horyzontalne */
                                        align-items: center;    /* Wypośrodkowanie wertykalne */
                                        padding: 0 10px;
                                        div.kosz {
                                            text-align: center;
                                            &:hover{
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                    &.dane {
                                        display: flex;
                                        flex-direction: column;
                                        flex-grow: 1;
                                        margin: 1px 0px;
                                        div.daty {
                                            display: flex;
                                            div.opis {
                                                width: 60%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                div.addLokalBox {
                    display: flex;
                    flex-direction: column;
                    div.rodzaj {
                        margin-top: 15px;
                        ul {
                            font-size: 11px;
                            li {
                                display: flex;
                                justify-content: space-between;
                                padding: 2px 0px;
                                text-align: center;
                                div {
                                    align-content: center;
                                }
                            }
                            &.head {
                                margin-right: 15px;
                                font-weight: 600;
                                li {
                                    
                                    &:first-child {
                                        font-weight: 600;
                                    }
                                }
                            }
                            &.listaLokal {
                                max-height: 150px;
                                overflow: auto;
                                li {
                                    flex: initial;
                                    div {
                                        
                                        svg {
                                            margin: 0;
                                        }
                                        input {
                                            padding: 2px;
                                            width: 90px;
                                        }
                                        &:last-child {
                                            display: flex;
                                            justify-content: space-between;
                                            div {
                                                align-items: center;
                                                svg {
                                                    margin-left: 5px!important;
                                                }
                                            }

                                        }
                                    }
                                    div:first-child {
                                        text-align: left;
                                    }

                                }
                            }
                        }
                    }
                }
                div.lokale {
                    padding: 5px 0px 0 15px;
                    width: 100%;
                    h4 {
                        margin: 0px 20px 0 20px;
                    }
                    div.lokal {
                        ul {
                            padding: 0 0 5px 0;
                            width: initial;
                            li {
                                padding: 5px 0 0 0;
                                div {
                                    flex: initial!important;
                                }
                                div.w150 {
                                    width: 150px;
                                    font-weight: 600;
                                }
                                div:last-child {
                                    font-weight: initial;
                                }
                                &:not(:first-child):hover {
                                    background-color:initial;
                                }
                            }
                            div.edit-data-od {
                                display: flex;
                                margin: 10px 0;
                                align-items: center;
                                div {
                                    width: 150px;
                                    font-weight: 600;
                                }
                                svg {
                                    display: block;
                                    margin-left: 10px;
                                    padding: 2px 5px;
                                }
                            }
                        }
                    }
                }
                div.grupa {
                    margin: 0px 0px 0 10px;
                    padding: 15px 0 15px 10px;
                    // background-color: var(--wnetrzeRamki);
                    background-color: var(--tlo);
                    overflow: hidden;
                    div.naglowek {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row;
                        h3 {
                            display: block;
                            margin: 15px 0 5px 5px;
                        }
                        h5 {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                        }
                        div.buttons {
                            display: flex;
                            flex-direction: column;
                        }
                    }

                    ul.udzialKonto {
                        li {
                            div {
                                flex: initial;
                                &:first-child {
                                    width: 200px;
                                    font-weight: 600;
                                }
                                &:last-child {
                                    font-weight: initial;
                                }
                            }
                            &:not(:first-child):hover {
                                background-color:initial;
                            }
                        }
                    }
                }
            }
        }
        div.mieszkancyDaneContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            h3, h4 {
                margin: 20px 0 0px 20px;
                width: fit-content;
                span {
                    font-size: 0.8rem;
                    text-decoration: underline;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            h4 {
                margin: 10px;
            }
            div.lewa {
                display: flex;
                flex-direction: column;
                div.ramka {
                    padding: 10px;
                    border: 1px solid var(--ramka);
                    border-radius: 5px;
                    height: fit-content;
                    min-width: 350px;
                    background-color: var(--wnetrzeRamki);
                    div.lista {
                        width: 100%;
                        max-width: 450px;
                        height: fit-content;
                        padding: 0 10px;
                        border-radius: 5px;
                        background-color: var(--wnetrzeRamki);
                        border: 1px solid var(--ramka);
                        ul.mieszkancyDane {
                            width: 100%;
                            li {
                                display: flex;
                                padding: 5px 10px;
                                border-bottom: solid 1px var(--ramka);;
                                border-radius: 0px;
                                div.first {
                                    flex: initial;
                                    font-weight: 600;
                                    width: 150px;
                                }
                                div.pwd {
                                    display: flex;
                                    flex: 1 1;
                                    flex-direction: row;
                                    text-align: center;
                                    
                                    div {
                                        font-weight: initial;
                                        &:first-child {
                                            text-align: left;
                                        }
                                        
                                    }
        
                                }
                                div:last-child {
                                    
                                }
                                &:first-child {
                                    font-weight:initial;
                                    border-bottom: solid 1px var(--ramka);;
                                    border-radius: 0px;
                                }
                                &:hover {
                                    background-color: initial;
                                }
                            }
                        }
                        div.form {
                            h4 {
                                margin: 20px;
                            }
                        }
                    }
                }
            }


            div.grupy {
                width: 50%;
                height: fit-content;
                overflow: hidden;
                border: 1px solid var(--ramka);
                background-color: var(--wnetrzeRamki);
                border-radius: 5px;
                padding-right: 10px;
                padding-bottom: 10px;
                div.form {
                    margin: 10px 0px 0 10px;
                    padding: 15px 20 15px 20px;
                    // background-color: var(--wnetrzeRamki);
                    background-color: var(--tlo);
                    h5 {
                        margin: 20px 0 0 0;
                    }
                    div.naglowek {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row;
                        h3 {
                            display: block;
                            margin: 15px 0 5px 5px;
                        }
        
                    }
                    div.stawkiInd {
                        h5 {
                            margin-bottom: 15px;
                        }
                        input[type='date'] {
                            margin-bottom: 15px;
                        }
                        div.stawkiIndHist {
                            display: flex;
                            flex-direction: column;
                            ul {
                                list-style: none;
                                li {
                                    display: flex;
                                    div {
                                        text-align: center;
                                        font-size: 14px;
                                        &.b {
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                        }
                        div.listaInd {
                            display: flex;
                            flex-direction: column;
                            div.listaIndWiersz {
                                display: flex;
                                div {
                                    width: 50%;
                                    input {
                                        width: 100px;
                                    }
                                    &.opis {
                                        label.b {
                                            font-weight: 600;
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ul.udzialKonto {
                        li {
                            display: flex;
                            justify-content: space-between;
                            div {
                                flex: initial;
                                display: flex;
                                align-items: center;
                                &:first-child {
                                    width: 200px;
                                    font-weight: 600;
                                }
                                &:last-child {
                                    font-weight: initial;
                                }
                            }
                            &:not(:first-child):hover {
                                background-color:initial;
                            }
                        }
                    }
                    div.listaBoxy {
                        max-height: 300px;
                        overflow: auto;
                        margin: 5px 0 0  0;
                        div.checkbox {
                            display: flex;
                            width: 100%;
                            input, label {
                                display: inline-block;
                            }
                            label {
                                width: 100%;
                                font-size: 12px;
                            }
                        }
                    }
                    div.planowaneZuzycie {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        flex-grow: 1;
                        width: 100%;
                        margin: 15px 0 0 0;
                        div.naglowek {
                            height: 75px;
                            h4 {
                                margin: 0 25px 25px 25px;
                            }
                        }
                        div.formularz {
                            display: flex;
                            flex-direction: column;
                            width: 80%;
                            div.wiersz {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                flex-grow:1;
                                margin: 5px 0;
                                
                                label {
                                    padding: 5px;
                                    width: 200px;
                                    font-size: 14px;
                                }
                                div.input {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    input {
                                        width: 150px;
                                        margin: 0 10px 0 0 ;
                                    }
                                    span {
                                        width: 20px;
                                        
                                    }
                                }
                                &.button {
                                    justify-content: center;
                                    button {
                                        width: fit-content;
                                    }
                                }
        
                            }
        
                        }
                        div.lista {
                            display: flex;
                            flex-direction: column;
                            width: 80%;
                            ul {
                                li {
                                    display: flex;
                                    flex-direction: row;
                                    margin: 15px 0;
                                    padding: 5px 10px;
                                    border-radius: 5px;
                                    div.dane {
                                        display: flex;
                                        flex-direction: column;
                                        width: 100%;
                                        div.wierszData {
                                            display: flex;
                                            justify-content: space-between;
                                            width: 50%;
                                            margin: 0 0 5px 0;
                                            div.value {
                                                font-weight: 600;
                                            } 
                                        }
                                        div.wierszZuzycia {
                                            display: flex;
                                            flex-direction: row;
                                            div {
                                                width: 50%;
                                            }
                                        }
                                    }
                                    div.kosz {
                                        min-width: 20px;
                                        font-size: 20px;
                                    }
                                    &:hover {
                                        background-color: var(--podswietlenieListy)
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }
    }



}