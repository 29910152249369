@charset "UTF-8";
@media (min-width: 320px) {
  div.mobile-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--footer);
  }
  div.mobile-menu div.bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 80px;
  }
  div.mobile-menu div.bar div.bar-logo {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 10px 20px;
  }
  div.mobile-menu div.bar div.bar-logo div.logo {
    width: 162px;
    height: 64px;
    background-image: url("../images/Biuro  obsługi nieruchomości_Mojsiewicz&Stanek_logo ok_przezroczyste.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  div.mobile-menu div.bar div.h {
    font-size: 3rem;
    padding: 20px 20px;
  }
  div.mobile-menu aside.sidebar.hidden {
    display: none;
  }
  div.mobile-menu aside.sidebar.visible {
    display: block;
  }
  div.mobile-menu aside.sidebar {
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    overflow-x: hidden;
    background-color: var(--footer);
  }
  div.mobile-menu nav {
    position: relative;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  div.mobile-menu nav div.bar-logo {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px 20px;
    margin: 30px 0;
  }
  div.mobile-menu nav div.bar-logo div.logo {
    width: 217px;
    height: 85px;
    background-image: url("../images/Biuro  obsługi nieruchomości_Mojsiewicz&Stanek_logo ok_przezroczyste.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  div.mobile-menu nav div.positions {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  div.mobile-menu nav div.positions ul {
    list-style: none;
  }
  div.mobile-menu nav div.positions ul li {
    margin: 10px 0;
  }
  div.mobile-menu nav div.positions ul li a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: black;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
    padding: 10px;
  }
}
@media (min-width: 1024px) {
  div.mobile-menu {
    display: none;
  }
  nav {
    display: flex;
    width: 100%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    z-index: 1000;
  }
  nav div.main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    border-radius: 30px;
    background-color: var(--footer);
    border-radius: 20px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.19);
  }
  nav div.main div.logo {
    width: 150px;
    height: 59px;
    background-image: url("../images/Biuro  obsługi nieruchomości_Mojsiewicz&Stanek_logo ok_przezroczyste.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 5px 15px;
  }
  nav div.main div.menu {
    display: flex;
    padding: 0 10px;
  }
  nav div.main div.menu ul {
    display: flex;
    flex-direction: row;
    margin: 20px;
    width: -moz-fit-content;
    width: fit-content;
    align-items: center;
    list-style: none;
  }
  nav div.main div.menu ul li a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: black;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;
  }
  nav div.main div.menu ul li a.active {
    border-bottom: 2px gray solid;
    transform: translateX(0);
  }
  nav div.main div.menu button {
    margin: auto 20px;
  }
  nav.menu {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1000;
  }
}/*# sourceMappingURL=navigation.css.map */