div.mieszkaniecWydruk, div.rozliczenieWydruk, div.glosowanieWydruk {
    position: fixed; /* Ustawiamy tło jako fixed, aby zajmowało cały ekran */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* 100% wysokości widoku przeglądarki */
    background-color: rgba(0, 0, 0, 0.5); /* Półprzezroczyste tło */
    z-index: 1; /* Tło powinno być poniżej modala */
    display: flex;
    justify-content: center;
    overflow: auto;
    div.modal {
        background-color: white;
        margin: 50px 0;
        padding: 20px;
        height: fit-content;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        z-index: 10; /* Modal powinien być nad tłem */
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: fit-content;
        box-sizing: border-box; /* Uwzględnia padding w wymiarach elementu */
        border-radius: 2px;
    }
}

div.content {
    div.wydruk {
        display: flex;
        width: 100%;
        justify-content: center;
        font-size: 14px;
        line-height: 1.8em;
        div.pass-title {
            width: 100%;
            display: flex;
            justify-content: center;
            div.A4 {
                width: 210mm; 
                height: 297mm;
                padding: 50px 40px; 
                page-break-before: always; 
                page-break-after: always;
                border: 1px solid;
                background-color: white;
                div.naglowek {
                    display: flex;
                    justify-content: space-between;
                    div.nadawca {
                        &.pieczatka {
                            text-align: center;
                        }
                    }
                    div.miejscowosc {
            
                    }
                }
                div.adresat {
                    display: flex;
                    justify-content: right;
                    margin-top: 50px;
                    font-size: 16px;
                    font-weight: 700;
                    div.dane {
                        width: 50%;
                    }
                }
                &.listaObecnosci {
                    div.tytul {
                        font-weight: 800;
                        text-align: center;
                        margin: 15px 0 40px 0;
                        input {
                            display: inline;
                            width: 190mm!important;
                            text-align: center;
                        }
                    }
                    div.naglowekTabeli {
                        border-left: 1px solid;
                        border-top: 1px solid;
                        border-right: 1px solid;
                        padding: 10px 0;
                        text-align: center;
                        text-transform: uppercase;      
                        font-weight: 800;
                    }
        
                    table.listaObecnosci {
                        tr {
                            th {
                                text-align: center;
                                padding: 0;
                                &.podpis {
                                    width: 200px;
                                }
                                &.w-50 {
                                    width: 90px;
                                }
                                &.w-30 {
                                    width: 30px;
                                }
                            }
                            td {
                                line-height: 1.2rem;
                                &.imieNaziwsko {
                                    text-align: left;
                                }
                                &.central {
                                    text-align: center;
                                }
        
                            }
                        }
    
                    }
                }
            }
        }

    }
    
}


// @media print {
//     table {
//       width: 100%;
//       border-collapse: collapse;
//     }
  
//     tr {
//       page-break-inside: avoid;
//     }
  
//     thead {
//       display: table-header-group;
//     }
  
//     tfoot {
//       display: table-footer-group;
//     }
// }