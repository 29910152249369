div.formDodajGrupe {
    // width: 33%;
    padding: 20px;
    height: fit-content;
    p, h5 {
        margin: 20px 0 0 0;
    }
    input.red {
        border: 1px solid red;
        color: red;
    }
    div.listaBoxy {
        max-height: 300px;
        overflow: auto;
        padding: 2px 7px;
        span.brak {
            font-size: 0.9rem;
        }
        div.checkbox {
            display: flex;
            width: 100%;
            input, label {
                display: inline-block;
            }
            label {
                width: 100%;
                font-size: .9rem;
            }
        }
    }
}