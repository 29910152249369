@charset "UTF-8";
div.content {
  padding: 5px 20px 10px 20px;
  justify-content: center;
  /*div.stawkiContainer {
      display: flex;
      flex-direction: row;
      margin-bottom: 100px;
      div.lista {
          width: 55%;
      }
      div.arch {
          flex: 1;
          padding: 0 15px;
          ul.listaArch {
              li {
                  display: flex;
                  margin: 10px 10px;
              }
          }
      }
  }*/
}
div.content.udzialy {
  max-width: 1200px;
}
div.content h1.nazwa {
  margin: 0 0 10px 0;
}
div.content h3.obowiazywanie {
  margin: 0 0 20px 0;
}
div.content table.tabela {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid;
}
div.content table.tabela thead tr th, div.content table.tabela thead tr td {
  font-size: 10px;
  border: 1px solid;
  padding: 5px 5px;
  flex: 1;
  text-align: center;
}
div.content table.tabela tbody tr:hover {
  background-color: var(--podswietlenieListy);
}
div.content table.tabela tbody tr td {
  font-size: 10px;
  border: 1px solid;
  padding: 5px 5px;
  flex: 1;
  text-align: center;
}
div.content table.tabela tfoot tr td {
  font-size: 10px;
  border: 1px solid;
  padding: 5px 5px;
  flex: 1;
  text-align: center;
  font-weight: 600;
}
div.content div.zbiorowka {
  max-height: 800px;
  overflow: auto;
}
div.content div.zbiorowka table.zbiorowka {
  width: -moz-fit-content;
  width: fit-content;
}
div.content table.naleznosci {
  width: -moz-fit-content;
  width: fit-content;
}
div.content table.naleznosci tbody tr td:last-child {
  font-weight: 600;
  white-space: nowrap;
}
div.content div.table-container {
  overflow-y: auto;
  position: relative;
}
div.content div.table-container table.naleznosci, div.content div.table-container table.zbiorowka {
  width: -moz-fit-content;
  width: fit-content;
}
div.content div.table-container table.naleznosci thead tr, div.content div.table-container table.zbiorowka thead tr {
  position: sticky; /* Ustawia nagłówki jako 'przyklejone' */
  top: 0; /* Ustawia je na górze kontenera */
  z-index: 1; /* Ustawia z-index, aby nagłówki były nad resztą tabeli */
  background-color: var(--tlo);
  border: 1px solid;
  font-size: 12px !important;
}
div.content ul.lista {
  font-size: 12px;
  list-style: none;
}
div.content ul.lista li {
  display: flex;
  padding: 5px 10px;
  border-radius: 5px;
  align-items: center;
}
div.content ul.lista li.active {
  background-color: var(--podswietlenieListy);
}
div.content ul.lista li div {
  flex: 1;
  align-content: center;
}
div.content ul.lista li:first-child {
  font-weight: 500;
  border-bottom: 1px solid #e9ecef;
  border-radius: 0px;
}
div.content ul.lista li.head {
  font-weight: 600;
  border-bottom: 1px solid #e9ecef;
  border-radius: 0px;
}
div.content ul.lista li.g {
  background-color: #e9e1db;
}
div.content ul.lista li:not(:first-child):hover {
  background-color: var(--podswietlenieListy);
}
div.content ul.lista li.lastRow {
  border-top: 1px solid var(--ramka);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
div.content ul.lista li.lastRow:hover {
  background-color: initial;
}
div.content ul.stawkiInd li > div:first-child {
  flex: initial;
  width: 200px;
}
div.content ul.stawki {
  width: 75%;
}
div.content ul.stawki li {
  padding: 5px 0 5px 10px;
  border-bottom: solid 1px;
  border-radius: 0px;
}
div.content ul.konta {
  margin-top: 30px;
}

ul.fakturyNaglowekLista {
  width: 100%;
}
ul.fakturyNaglowekLista li {
  display: flex;
}
ul.fakturyNaglowekLista li.naglowek {
  margin: 15px 0px;
}
ul.fakturyNaglowekLista li:hover {
  background-color: rgb(230, 228, 228);
}
ul.fakturyNaglowekLista li div {
  width: 25%;
  padding: 5px 0px;
}/*# sourceMappingURL=listy.css.map */