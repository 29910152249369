ul.wspolnotyLista li {
    display: flex;
    margin: 20px 10px;
    &:first-child {
        font-weight: 600;
      }
    div {
        width: 25%;
    }
}

ul.wspolnoty {
    max-height: 500px;
    overflow: auto;
    li:first-child {
        font-weight: initial!important;
    }
}

div.wyszukaj {
    display: flex;
    position: relative;
    align-items: center;
    input.searchMieszkaniec {
        width: 600px;
        margin: 5px;
        padding: 5px;
        border: 1px solid gainsboro;
        font-size: 14px;
    }
    div.hintList {
        position: absolute;
        left: 40px;
        top: 33px;
        z-index: 10;
        width: 595px;
        max-height: 500px;
        overflow: auto;
        div.hint {
            padding: 5px;
            background-color: white;
            font-size: 10px;
            cursor: pointer;
            width: 100%;
            span {
                font-weight: 500;
            }
            &:hover {
                background-color: #e9ecef;
            }
            
        }
    }
}

div.content {
    div.ulice {
        div.dodaj {
            display: flex;
            justify-content: space-around;
            margin: 30px 0;

            div.lokal_dodaj {
                width: 33%;
                padding: 15px;
                border: 1px solid var(--podswietlenie);
                border-radius: 15px;
                div.select {
                    margin: 0 0 20px 0;
                    p {
                        display: inline-block;
                        width: 200px;
                    }
                }
                div.input {
                    margin: 0 0 20px 0;
                    p {
                        display: inline-block;
                        width: 200px;
                    }
                    input {
                        display: inline-block;
                        width: 100px;
                    }
                }
            }
        }
        div.listy {
            display: flex;
            padding: 20px 0;
            justify-content: space-around;
            div.listaUlic {
                width: 33%;
                ul {
                    cursor: pointer;
                }
            }
            div.lokale {
                width: 33%;
                ul.scroll {
                    max-height: 300px;
                    overflow: auto  ;
                }
            }
        }
    }
}

 


