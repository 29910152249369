@media (min-width: 320px) {
  footer {
    background-color: var(--tlo);
    padding: 15px 0 0 0;
  }
  footer div.content {
    padding: initial;
    display: flex;
    flex-direction: column;
  }
  footer div.content div.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e3d7cf;
    border-radius: 10px;
    padding: 10px 15px;
    margin: 0;
  }
  footer div.content div.contact div {
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  footer div.content div.contact div p {
    margin: 15px 0;
    padding: 0 5px;
  }
  footer div.content div.contact div p.head {
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  footer div.content div.contact div.c1 div.logo {
    text-align: center;
  }
  footer div.content div.contact div.c1 div.logo img {
    height: auto;
    width: 250px;
  }
  footer div.content div.contact div.c1 div.socials {
    display: flex;
    flex-direction: row;
    text-align: center;
    margin-top: 15px;
  }
  footer div.content div.contact div.c1 div.socials a {
    display: block;
  }
  footer div.content div.contact div.c1 div.socials a svg {
    margin: 10px;
  }
  footer div.content div.contact div.c2 div, footer div.content div.contact div.c3 div {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  footer div.content div.contact div.c2 div p, footer div.content div.contact div.c3 div p {
    display: block;
    font-size: 0.9rem;
    width: 50%;
    margin: 5px 0;
    text-wrap: nowrap;
  }
  footer div.content div.contact div.c2 div p:first-child, footer div.content div.contact div.c3 div p:first-child {
    font-weight: 600;
  }
  footer div.content div.contact div.c2 div p:last-child, footer div.content div.contact div.c3 div p:last-child {
    text-align: right;
  }
  footer div.content div.contact div.c4 p {
    font-size: 0.9rem;
    margin: 5px 0;
    display: block;
  }
  footer div.content div.contact div.c4 p.head {
    font-weight: 400;
    text-transform: uppercase;
    margin: 15px 0;
    margin-bottom: 20px;
    font-size: initial;
  }
  footer div.content div.contact div.c4 p.brand {
    font-size: 0.9rem;
    margin: 5px 0;
    font-weight: 600;
  }
  footer div.content div.contact div.c4 div {
    margin-top: 15px;
  }
  footer div.content div.singature {
    padding: 20px 0;
  }
  footer div.content div.singature p {
    color: #c2bab9;
    text-align: center !important;
    margin: 10px;
    font-size: 0.8rem;
  }
}
@media (min-width: 1024px) {
  footer {
    padding: 50px 0 0 0;
  }
  footer div.content div.contact {
    flex-direction: row;
    align-items: initial;
    margin: 0 30px;
    border-radius: 30px;
    padding: 30px 40px;
  }
  footer div.content div.contact div p {
    padding: 0 35px;
  }
  footer div.content div.singature {
    padding: 20px 0;
  }
  footer div.content div.singature p {
    color: #c2bab9;
    text-align: center !important;
    margin: 10px;
    font-size: 0.8rem;
  }
}/*# sourceMappingURL=footer.css.map */