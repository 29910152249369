@charset "UTF-8";
div.mieszkaniecWydruk, div.rozliczenieWydruk, div.glosowanieWydruk {
  position: fixed; /* Ustawiamy tło jako fixed, aby zajmowało cały ekran */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* 100% wysokości widoku przeglądarki */
  background-color: rgba(0, 0, 0, 0.5); /* Półprzezroczyste tło */
  z-index: 1; /* Tło powinno być poniżej modala */
  display: flex;
  justify-content: center;
  overflow: auto;
}
div.mieszkaniecWydruk div.modal, div.rozliczenieWydruk div.modal, div.glosowanieWydruk div.modal {
  background-color: white;
  margin: 50px 0;
  padding: 20px;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10; /* Modal powinien być nad tłem */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: -moz-fit-content;
  width: fit-content;
  box-sizing: border-box; /* Uwzględnia padding w wymiarach elementu */
  border-radius: 2px;
}

div.content div.wydruk {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 14px;
  line-height: 1.8em;
}
div.content div.wydruk div.pass-title {
  width: 100%;
  display: flex;
  justify-content: center;
}
div.content div.wydruk div.pass-title div.A4 {
  width: 210mm;
  height: 297mm;
  padding: 50px 40px;
  page-break-before: always;
  page-break-after: always;
  border: 1px solid;
  background-color: white;
}
div.content div.wydruk div.pass-title div.A4 div.naglowek {
  display: flex;
  justify-content: space-between;
}
div.content div.wydruk div.pass-title div.A4 div.naglowek div.nadawca.pieczatka {
  text-align: center;
}
div.content div.wydruk div.pass-title div.A4 div.adresat {
  display: flex;
  justify-content: right;
  margin-top: 50px;
  font-size: 16px;
  font-weight: 700;
}
div.content div.wydruk div.pass-title div.A4 div.adresat div.dane {
  width: 50%;
}
div.content div.wydruk div.pass-title div.A4.listaObecnosci div.tytul {
  font-weight: 800;
  text-align: center;
  margin: 15px 0 40px 0;
}
div.content div.wydruk div.pass-title div.A4.listaObecnosci div.tytul input {
  display: inline;
  width: 190mm !important;
  text-align: center;
}
div.content div.wydruk div.pass-title div.A4.listaObecnosci div.naglowekTabeli {
  border-left: 1px solid;
  border-top: 1px solid;
  border-right: 1px solid;
  padding: 10px 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
}
div.content div.wydruk div.pass-title div.A4.listaObecnosci table.listaObecnosci tr th {
  text-align: center;
  padding: 0;
}
div.content div.wydruk div.pass-title div.A4.listaObecnosci table.listaObecnosci tr th.podpis {
  width: 200px;
}
div.content div.wydruk div.pass-title div.A4.listaObecnosci table.listaObecnosci tr th.w-50 {
  width: 90px;
}
div.content div.wydruk div.pass-title div.A4.listaObecnosci table.listaObecnosci tr th.w-30 {
  width: 30px;
}
div.content div.wydruk div.pass-title div.A4.listaObecnosci table.listaObecnosci tr td {
  line-height: 1.2rem;
}
div.content div.wydruk div.pass-title div.A4.listaObecnosci table.listaObecnosci tr td.imieNaziwsko {
  text-align: left;
}
div.content div.wydruk div.pass-title div.A4.listaObecnosci table.listaObecnosci tr td.central {
  text-align: center;
}/*# sourceMappingURL=wydruk.css.map */