div.stawki {
    width: 100%;
    div.stawkiJednorazoweGrupy {
        display: flex;
        flex-direction: column;
        div.top {
            display: flex;
            ul {
                display: block;
                height: fit-content;
                min-width: 400px;
                max-height: 150px;
                overflow: auto;
                li {
                    div {
                        flex: initial;
                        &:nth-of-type(1) {
                            width: 100px;
                        }
                        &:nth-of-type(2) {
                            width: 300px;
                        }
                        &:nth-of-type(3) {
                            width: 100x;
                        }
                    }
                }
            }
            div.wybor {
                display: flex;
                // justify-content: space-around;
                div {
                    margin: 0 20px 5px 20px;
                    width: fit-content;
                    h4 {
                        margin: 0 0 20px 0;
                    }
                }
            }
        }
        div.stawkiJednorazoweGrupyLista {
            ul {
                max-height: 400px;
                overflow: auto;
            }
        }
        min-height: 600px;
        h3 {
            margin: 20px 0 20px 0;
        }


        div.stawkaForm {
            display: flex;
            flex-direction: column;
            div.wiersz {
                margin: 0 0 20px 0;
                label {
                    display: block;
                    width: 100px;
                }
                input {
                    // width: 200px;
                }
            }
        }
    }
    h1.nazwa {
        margin: 0 0 10px 0;
    }
    div.stawkiContainer {
        display: flex;
        flex-direction: column;
        h3 {
            margin: 20px 0 20px 0;
        }
        div.listy {
            display: flex;
            flex-direction: row;
            div.lista {
                width: 50%;
                ul {
                    margin: 0 0 20px 0;
                    li {
                        div.klucz {
                            width: 50%;
                        }
                        div.wartosc {
                            width: 15%;
                        }
                    }
                }
            }
            div.arch {
                flex: 1;
                ul.listaArch {
                    width: 25%;
                    li {
                        display: flex;
                        justify-content: space-between;
                        padding: 3px 5px;
                        margin: 2px;
                        &:hover {
                            background-color:#e0d6cf;
                        }
                        div.icon {
                            margin: 0 5px;
                        }
                    }
                }
            }
        }
        div.formularz {
            margin: 20px;
            width: 80%;
            div.wiersz {
                display: flex;
                flex-direction: row;
                margin: 10px 0;
                div.check {
                    width: 33%;
                    input {
                        margin-right: 10px;
                    }
                }
                div.input {
                    width: 33%;
                }
                div.lista {
                    width: 33%;
                }
            }
            div.button {
                display: flex;
                justify-content: center;
            }
        }
    }

}

