@media (min-width: 320px) {
  div.login {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  div.login section.cienie {
    margin: 50px auto;
    height: -moz-fit-content;
    height: fit-content;
    padding: 50px 55px;
    background-color: white;
  }
  div.login section.cienie h1 {
    margin: 20px 0 40px 0;
  }
  div.login section.cienie form section.user {
    margin-bottom: 30px;
  }
  div.login section.cienie form section label {
    border: 0;
  }
  div.login section.cienie form section input {
    width: 200px;
  }
  div.login section.cienie form section div.pwd {
    display: flex;
    align-items: center;
  }
  div.login section.cienie form section div.pwd input {
    display: block;
    width: 180px;
  }
  div.login section.cienie form div.button {
    width: 100%;
    text-align: center;
  }
  div.login section.cienie form span.warning {
    display: block;
    max-width: 175px;
    font-weight: 700;
    text-align: center;
  }
}
@media (min-width: 1150px) {
  div.login {
    transform: translateY(-200px);
  }
}/*# sourceMappingURL=login.css.map */