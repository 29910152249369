@media (min-width: 320px) {
  div.content.ksiegowosc div.buttons {
    flex-wrap: wrap;
  }
  div.content.ksiegowosc div.buttons button {
    margin: 2px 4px;
  }
  div.content.ksiegowosc div.window div.przelewy {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  div.content.ksiegowosc div.window div.przelewy h4 {
    width: 100%;
  }
  div.content.ksiegowosc div.window div.przelewy div.import {
    display: flex;
    flex-direction: column;
  }
  div.content.ksiegowosc div.window div.przelewy div.import div.form {
    display: flex;
    align-items: center;
  }
  div.content.ksiegowosc div.window div.przelewy div.pliki {
    width: 50%;
  }
  div.content.ksiegowosc div.window div.przelewy div.import, div.content.ksiegowosc div.window div.przelewy div.historia, div.content.ksiegowosc div.window div.przelewy div.pliki {
    margin: 20px 0px 10px 0px;
  }
  div.content.ksiegowosc div.window div.przelewy div.import select, div.content.ksiegowosc div.window div.przelewy div.historia select, div.content.ksiegowosc div.window div.przelewy div.pliki select {
    margin: 20px;
  }
  div.content.ksiegowosc div.window div.przelewy div.import input, div.content.ksiegowosc div.window div.przelewy div.historia input, div.content.ksiegowosc div.window div.przelewy div.pliki input {
    margin: 10px 20px 30px 50px;
  }
  div.content.ksiegowosc div.window div.przelewy div.import h5, div.content.ksiegowosc div.window div.przelewy div.historia h5, div.content.ksiegowosc div.window div.przelewy div.pliki h5 {
    margin: 10px 0 30px 0;
  }
  div.content.ksiegowosc div.window div.przelewy div.import table, div.content.ksiegowosc div.window div.przelewy div.historia table, div.content.ksiegowosc div.window div.przelewy div.pliki table {
    font-size: 10px;
    width: 100%;
  }
  div.content.ksiegowosc div.window div.przelewy div.import table tbody tr:hover, div.content.ksiegowosc div.window div.przelewy div.historia table tbody tr:hover, div.content.ksiegowosc div.window div.przelewy div.pliki table tbody tr:hover {
    background-color: var(--podswietlenieListy);
  }
  div.content.ksiegowosc div.window div.przelewy div.import table tbody tr.g, div.content.ksiegowosc div.window div.przelewy div.historia table tbody tr.g, div.content.ksiegowosc div.window div.przelewy div.pliki table tbody tr.g {
    background-color: var(--podswietlenie);
  }
  div.content.ksiegowosc div.window div.przelewy div.import table tbody tr th, div.content.ksiegowosc div.window div.przelewy div.historia table tbody tr th, div.content.ksiegowosc div.window div.przelewy div.pliki table tbody tr th {
    text-align: left;
    border-bottom: 1px solid var(--ramka);
    padding: 5px 5px;
  }
  div.content.ksiegowosc div.window div.przelewy div.import table tbody tr td, div.content.ksiegowosc div.window div.przelewy div.historia table tbody tr td, div.content.ksiegowosc div.window div.przelewy div.pliki table tbody tr td {
    padding: 3px 5px;
  }
  div.content.ksiegowosc div.window div.przelewy div.import table tbody tr td select, div.content.ksiegowosc div.window div.przelewy div.historia table tbody tr td select, div.content.ksiegowosc div.window div.przelewy div.pliki table tbody tr td select {
    margin: 0;
    padding: 1px 5px;
  }
  div.content.ksiegowosc div.window div.przelewy div.import table tbody tr td.ost, div.content.ksiegowosc div.window div.przelewy div.historia table tbody tr td.ost, div.content.ksiegowosc div.window div.przelewy div.pliki table tbody tr td.ost {
    display: flex;
    height: 30px;
    align-items: center;
  }
  div.content.ksiegowosc div.window div.przelewy div.import table tbody tr td.ost span, div.content.ksiegowosc div.window div.przelewy div.historia table tbody tr td.ost span, div.content.ksiegowosc div.window div.przelewy div.pliki table tbody tr td.ost span {
    display: block;
    width: 15px;
    margin: 0 5px;
  }
  div.content.ksiegowosc div.window div.przelewy div.import div.send, div.content.ksiegowosc div.window div.przelewy div.historia div.send, div.content.ksiegowosc div.window div.przelewy div.pliki div.send {
    display: flex;
    justify-content: center;
  }
  div.content.ksiegowosc div.window div.przelewy p.saldo_w {
    margin-bottom: 30px;
  }
  div.content.ksiegowosc div.window div.przelewy div.przeksiegowaniaForm {
    display: flex;
    flex-direction: column;
    width: 80%;
  }
  div.content.ksiegowosc div.window div.przelewy div.przeksiegowaniaForm div.listy {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 10px 0;
  }
  div.content.ksiegowosc div.window div.przelewy div.przeksiegowaniaForm div.listy div.zNa {
    display: flex;
    justify-content: center;
    width: 45%;
    align-content: center;
    align-items: center;
  }
  div.content.ksiegowosc div.window div.przelewy div.przeksiegowaniaForm div.listy div.zNa span {
    display: block;
    margin: 0 20px 0 0;
  }
  div.content.ksiegowosc div.window div.przelewy div.przeksiegowaniaForm div.form {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
  }
  div.content.ksiegowosc div.window div.przelewy div.przeksiegowaniaForm div.form div {
    display: flex;
    align-items: center;
    margin: 0 15px;
  }
  div.content.ksiegowosc div.window div.przelewy div.przeksiegowaniaForm div.form div input, div.content.ksiegowosc div.window div.przelewy div.przeksiegowaniaForm div.form div button {
    margin: 0 10px;
    display: block;
  }
  div.content.ksiegowosc div.window div.przelewy div.przeksiegowaniaForm div.form div input.opis {
    width: 200px;
  }
  div.content.ksiegowosc div.window div.przelewy div.przeksiegowaniaForm div.form input {
    height: 30px;
  }
  div.content.ksiegowosc div.window div.historia {
    display: flex;
    flex-direction: column;
  }
  div.content.ksiegowosc div.window div.historia div.filtry {
    display: flex;
    justify-content: flex-start;
    padding: 0 15px 15px 15px;
  }
  div.content.ksiegowosc div.window div.historia div.tabela {
    max-height: 500px;
    overflow: auto;
  }
  div.content.ksiegowosc div.window div.historia div.tabela table {
    border-collapse: separate;
    border-spacing: 0;
  }
  div.content.ksiegowosc div.window div.historia div.tabela table thead tr th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--tlo);
  }
}
@media (min-width: 1024px) {
  div.content.ksiegowosc div.buttons button {
    margin: 10px 20px;
  }
}/*# sourceMappingURL=ksiegowosc.css.map */