@media (min-width: 320px) {
    div.glowna {
        display: flex;
        justify-content: center;
        div.text {
            width: 85%;
            div.wstep {
                display: flex;
                align-items: stretch;
                background-color: white;
                margin: 50px 0 0 0;
                div.opis {
                    width: 100%;
                    padding: 20px 10px 20px 15px;
                    height: auto;
        
                    h3 {
                        letter-spacing: 4px;
                        text-transform: uppercase;
                    }
                    div.separator {
                        width: 110px;
                        height: 5px;
                        margin: 15px 0 30px 0;
                        background-color: #f3bb97;
                    }
                    p {
                        margin: 5px 0;
                        line-height: 1.7;
                        span.link {
                            font-weight: 600;
                            text-decoration: underline;
                            line-height: 1.7;
                            cursor: pointer;
                        }
                        a {
                            text-decoration: underline;
                        }
                    }
                }

            }
            div.s2 {
                margin-bottom: 100px;
            }
            div.wspolpraca {
                position: fixed;
                // top: 50%;
                right: -100px; /* Ukryj początkowo element poza ekranem */
                transition: right 0.5s ease; /* Dodaj płynne przejście */
            }
            div.klienci {
                margin: 0 0 100px 0;
                h3 {
                    text-transform: uppercase;
                    font-weight: 500;
                }
                div.opinie {
                    display: flex;
                    justify-content: space-around;
                    margin: 30px 0 0 0 ;
                    div.opinia {
                        width: 25%;
                        min-height: 300px;
                        border-radius: 5px;
                        border: 1px solid var(--podswietlenie);
                        background-color: white;
                        padding: 50px 40px;
                        span.tresc {
                            font-style: oblique;
                        }
                        p.autor {
                            margin: 30px 0 0 0;
                            font-style: oblique;
                            text-align: right;
                        }
                    }
                }
            }
    
        }
    }
}

@media (min-width: 1024px) {
div.glowna {

    div.text {
        width: 70%;
        div.wstep {
            transform: translateY(-100px);   
            margin: initial;         
            div.opis {
                width: 65%;
                padding: 60px 40px 60px 45px;
            }

        }
        div.s1 {
            margin-bottom: 150px;
            div.photo {
                height: auto;
                width: 35%;
                background-image: url("../../public/img/pexels-jonathan-borba-5570224 ORI (1).jpg");
                background-position: center;
                background-size:cover;
            }
        }
        div.s2 {
            margin-bottom: initial;
            div.photo {
                height: auto;
                width: 35%;
                background-image: url("../../public/img/pexels-jonathan-borba-5570224 ORI (1).jpg");
                background-position: center;
                background-size:cover;
            }
        }
        div.wspolpraca {
            position: fixed;
            // top: 50%;
            right: -100px; /* Ukryj początkowo element poza ekranem */
            transition: right 0.5s ease; /* Dodaj płynne przejście */
        }
        div.klienci {
            margin: 0 0 100px 0;
            h3 {
                text-transform: uppercase;
                font-weight: 500;
            }
            div.opinie {
                display: flex;
                justify-content: space-around;
                margin: 30px 0 0 0 ;
                div.opinia {
                    width: 25%;
                    min-height: 300px;
                    border-radius: 5px;
                    border: 1px solid var(--podswietlenie);
                    background-color: white;
                    padding: 50px 40px;
                    span.tresc {
                        font-style: oblique;
                    }
                    p.autor {
                        margin: 30px 0 0 0;
                        font-style: oblique;
                        text-align: right;
                    }
                }
            }
        }

    }
}
}
