div.glosowanie {
    display: flex;
    flex-direction: column;
    align-items: center;
    div.wspolnoty-lista {
        width: 100%;
    }
    div.buttons {
        width: 100%;
    }
    div.przeglad {
        display: flex;
        justify-content: space-around;
        // padding: 0 5%;
        width: 100%;

        margin-top: 50px;
        div.przegladListaSzczegoly {
            height: fit-content;
            max-height: 500px;

            overflow: auto;
            padding: 10px;
            div.ramka {
                padding: 10px 20px;
            }
            div.karta {
                width: 400px;
                margin: 0 0 20px 0;
                &.active {
                    background-color: var(--wnetrzeRamkiHover);
                    border-width: 2px;                    
                }
                span {
                    font-size: 14px;
                }
                p.numer {
                    font-weight: 500;
                }
                &:hover {
                    background-color: var(--wnetrzeRamkiHover);
                }
            }
            div.szczegoly {
                width: 400px;
                margin: 0 0 20px 0;
                p.numer {
                    text-decoration: underline;
                    width: fit-content;
                }
                div.termin {
                    font-size: 13px;
                    margin: 10px 0;
                    border-bottom: 1px solid var(--ramkaLight);
                }
                &.active {
                    background-color: var(--wnetrzeRamkiHover);
                    border-width: 2px;                    
                }
                div.glosy {
                    font-size: 13px;
                    margin: 10px 0;
                    border-bottom: 1px solid var(--ramkaLight);

                }
            }
            div.glosy {
                margin: 0 0 20px 0;
                margin: 10px 0;
                &.active {
                    background-color: var(--wnetrzeRamkiHover);
                    border-width: 2px;                    
                }
                span {
                    font-size: 14px;
                }
            }
            div.szczegoly, div.glosy {
                table {
                    border-collapse: collapse;
                    thead {
                        th {
                            font-size: 11px;
                            border: 1px solid;
                            padding: 2px 3px;
                        }
                    }
                    tbody, tfoot {
                        td {
                            font-size: 10px;
                            border: 1px solid;
                            padding: 2px 6px;
                        }
                    }
                }
            }
        }

    }
    form {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 500;
        width: fit-content;
        padding: 30px 50px;
        border-radius: 5px;
        border: 1px solid var(--ramka);
        div {
            width: fit-content;;
        }
        svg {
            margin-left: 10px;
        }
        h3 {
            margin-bottom: 20px;
        }
        button {
            width: fit-content;
        }
        div.pytanie {
            display: flex;
            justify-content: space-between;
            padding: 5px 0;
            max-width: 400px;
        }
        div.nowePytanie {
            display: flex;
            flex-direction: column;
            // align-items: center;
            margin-top: 10px; 
            div.tresc {
                display: flex;
                align-items: center;
                button {
                    display: block;
                }
            }


        }
    }
}