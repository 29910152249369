@media (min-width: 320px) {
    div.kontakt {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        div.content {
            width: 85%;
            
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0;
            background-color: white;
            margin: 50px 0 50px 0;
            div.mapa {
                width: 100%;
            }
            div.names {
                width: 100%;
                align-content: center;
                text-align: center;
                display: flex;
                flex-direction: column;
                padding: 0 20px;
                div.naglowek {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 20px 0;
                    h3 {
                        font-size: 1.5rem;
                    }
                    div.separator {
                        width: 110px;
                        height: 3px;
                        margin: 15px 0 30px 0;
                        background-color: #f3bb97;
                    }
                }
                div.osoba {
                    margin: 20px 0;
                    h4 {
                        margin: 0 0 5px 0;
                    }
                }
    
            }
            div.formularz {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: white;
                width: 100%;
                position: relative;
                margin: 30px 20px;
                padding: 10px;
                h2 {
                    margin: 20px 0;
                }
                h3 {
                    font-weight: 400;
                    font-size: .9rem;
                    margin: 15px 0 0 0 ;
                    position: absolute;
                    bottom: 35px;
                }
                form {
                    display: block;
                    label {
                        margin-top: 15px;
                    }
                    textarea {
                        padding: 6px 12px;
                        border: none;
                        border-bottom: 1px solid #ccc;
                        min-width: 300px;
    
                    }
                    button {
                        display: block;
                    }
                }
            }
        }
    
    
    }
}

@media (min-width: 1024px) {
    div.kontakt {
        div.content {
            flex-direction: row;
            width: 70%;
            transform: translateY(-100px);
            margin: 0 0 50px 0;
            div.mapa {
                width: 100%;
            }
            div.names {
                width: 60%;
                align-content: center;
                text-align: center;
                display: flex;
                flex-direction: column;
                div.naglowek {
                    display: none;
                }
            }
            div.formularz {
                width: 40%;
            }
        }
    
    
    }
}
