
div.content {
    padding: 5px 20px 10px 20px;
    &.udzialy {
        max-width: 1200px;
    }
    h1.nazwa {
        margin: 0 0 10px 0;
    }
    h3.obowiazywanie {
        margin: 0 0 20px 0;
    }
    justify-content: center;
    
    table.tabela {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid;
        thead {
            tr {
                th, td {
                    font-size: 10px;
                    border: 1px solid ;
                    padding: 5px 5px;
                    flex: 1;
                    text-align: center;
                }
            }
        }
        tbody {
            tr {

                &:hover {
                    background-color: var(--podswietlenieListy);
                }
    
    
                td {
                    font-size: 10px;
                    border: 1px solid;
                    padding: 5px 5px;
                    flex: 1;
                    text-align: center;
                }
            }
        }
        tfoot {
            tr {
                td {
                    font-size: 10px;
                    border: 1px solid;
                    padding: 5px 5px;
                    flex: 1;
                    text-align: center;
                    font-weight: 600;
                }
            }
        }


    }
    div.zbiorowka {
        max-height: 800px;
        overflow: auto;
        table.zbiorowka {
            width: fit-content;
    
        }
    }

    table.naleznosci {
        width: fit-content;
        tbody {
            tr {
                td:last-child {
                    font-weight: 600;
                    white-space: nowrap;
                }
            }
        }
    }

    div.table-container {
        // max-height: 400px;
        overflow-y: auto;
        position: relative;
        table.naleznosci, table.zbiorowka {
            width: fit-content;
                thead tr {
                    position: sticky; /* Ustawia nagłówki jako 'przyklejone' */
                    top: 0; /* Ustawia je na górze kontenera */
                    z-index: 1; /* Ustawia z-index, aby nagłówki były nad resztą tabeli */
                    background-color: var(--tlo);
                    border: 1px solid;
                    font-size: 12px!important;
                }
        }
    }
    ul {
        &.lista {
            font-size: 12px;
            list-style: none;
            li {
                display: flex;
                padding: 5px 10px;
                border-radius: 5px;
                align-items: center;
                // border-bottom: 1px solid var(--podswietlenie);
                &.active {
                    background-color: var(--podswietlenieListy)
                }
                div {
                    flex: 1;
                    align-content: center;
                }
                &:first-child {
                    font-weight: 500;
                    border-bottom: 1px solid #e9ecef;
                    border-radius: 0px;
                }
                &.head {
                    font-weight: 600;
                    border-bottom: 1px solid #e9ecef;
                    border-radius: 0px;
                }
                // &:last-child {
                //     border-top: 1px solid var(--ramka);
                // }
                &.g {
                    background-color: #e9e1db;
                }
                &:not(:first-child):hover {
                    background-color: var(--podswietlenieListy)
                }
                
                &.lastRow {
                    border-top: 1px solid var(--ramka);
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    &:hover {
                        background-color: initial;
                    }
                }

            }
        }
        &.stawkiInd {
            li {
                >div:first-child {
                    flex: initial;
                    width: 200px;
                }
            }
        }
        &.stawki {
            width: 75%;
            li { 
                padding: 5px 0 5px 10px;
                border-bottom: solid 1px;
                border-radius: 0px;
            }
        }

        &.konta {
            margin-top: 30px;
        }

    }

    /*div.stawkiContainer {
        display: flex;
        flex-direction: row;
        margin-bottom: 100px;
        div.lista {
            width: 55%;
        }
        div.arch {
            flex: 1;
            padding: 0 15px;
            ul.listaArch {
                li {
                    display: flex;
                    margin: 10px 10px;
                }
            }
        }
    }*/
}


ul.fakturyNaglowekLista {
    width: 100%;
    li {
        display: flex;
        &.naglowek {
            margin: 15px 00px;
        }
        &:hover {
            background-color:rgb(230, 228, 228);
        }
        div {
            width: 25%;
            padding: 5px 0px;
        }
    }
}