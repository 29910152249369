div.dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 100;
    &.block {
        display: block;
    }
    &.none {
        display: none;
    }
    div.logo {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 40%;
        left: 50%;
        width: 150px;
        height: 150px;
        background-image: url("../../public/img/logo192.png");
        background-position: center;
        background-size: cover;
        border-radius: 75px;
        animation: rotate 4s linear infinite;
    }
    @keyframes rotate {
        from {
          transform: translate(-50%, -50%) rotate(0deg);
        }
        to {
          transform: translate(-50%, -50%) rotate(360deg);
        }
    }
    div.window {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: white;
        padding: 50px;
        border-radius: 5px;
        div.buttons {
            display: flex;
            align-items: center;
            margin: 30px 0 0 0;
        }
    }
}