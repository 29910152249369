@charset "UTF-8";
:root {
  --fontAwesome: #533938;
  --tlo: #f4eeea;
  --podswietlenie: rgb(230, 228, 228);
  --ramka: rgb(152, 152, 152);
  --ramkaLight: rgb(204, 204, 204);
  --footer: #e3d7cf;
  --podswietlenieListy: #e0d6cf;
  --wnetrzeRamki: rgb(249, 244, 240);
  --wnetrzeRamkiHover: rgb(252, 250, 248);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--tlo);
}

html {
  font-family: "lato";
}

svg {
  color: var(--fontAwesome) !important;
  cursor: pointer;
}

h2 {
  font-size: 1rem;
}

.u:hover {
  text-decoration: underline;
  cursor: default;
}

a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  color: initial;
}

div.wiersz {
  display: flex;
  align-items: center;
}

div.buttons {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
}

table {
  border-collapse: collapse;
}
table thead tr th, table thead tr td, table tbody tr th, table tbody tr td, table tfoot tr th, table tfoot tr td {
  padding: 1px 5px;
}
table thead tr th, table tbody tr th, table tfoot tr th {
  font-weight: 500;
}
table tfoot {
  font-weight: 500;
}
table tfoot tr td {
  white-space: nowrap;
}

@media (min-width: 320px) {
  div.app {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    background-color: var(--tlo);
  }
  div.app header {
    display: none;
  }
}
@media (min-width: 1024px) {
  div.app header {
    display: block;
    flex-basis: 50vh;
    background-image: url("../../public/img/Zdjęcie WhatsApp 2023-11-19 o 18.18.02_c14b4798.jpg");
    background-position: 100% 45%;
    background-size: cover;
  }
  div.app header.admin {
    flex-basis: 20vh;
  }
  div.app main {
    padding: 5px 30px 0 30px;
    background-color: var(--tlo);
    min-height: 50vh;
  }
  div.app aside {
    min-width: 200px;
    background-color: yellow;
  }
  div.app section.page {
    flex-grow: 1;
    background-color: var(--tlo);
  }
}
input [type=text] {
  max-height: 33px;
}

button, select {
  /* CSS */
  height: -moz-fit-content;
  height: fit-content;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #FAFBFC;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292E;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  list-style: none;
  padding: 3px 10px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
  margin: 10px 20px;
}
button:hover, select:hover {
  background-color: #F3F4F6;
  text-decoration: none;
  transition-duration: 0.1s;
}
button:disabled, select:disabled {
  background-color: #FAFBFC;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959DA5;
  cursor: default;
}
button:active, select:active {
  background-color: #EDEFF2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
}
button:focus, select:focus {
  outline: 1px transparent;
}
button:before, select:before {
  display: none;
}
button:-webkit-details-marker, select:-webkit-details-marker {
  display: none;
}

select {
  margin: 0;
}

.noSelect {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

div.buttons {
  display: flex;
  flex-direction: row;
  width: -moz-fit-content;
  width: fit-content;
}

.ramka {
  border: 1px solid var(--ramka);
  padding: 10px 10px;
  border-radius: 10px;
  background-color: var(--wnetrzeRamki);
  height: -moz-fit-content;
  height: fit-content;
  min-width: 350px;
  margin: 0 0 5px 0;
}
.ramka div.naglowek {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px 0;
}
.ramka div.naglowek h2 {
  display: block;
  width: -moz-fit-content;
  width: fit-content;
}
.ramka div.naglowek div.ikony svg {
  display: block;
}
.ramka div.naglowek svg {
  display: block;
}

.ramka-br {
  border: 1px solid var(--ramka);
  border-radius: 15px;
  padding: 1px;
}

.ramka-br-light {
  border: 1px solid var(--ramkaLight);
  border-radius: 15px;
  padding: 1px;
}

.ramka-br2 {
  border: 2px solid var(--ramka);
  border-radius: 15px;
  padding: 1px;
}

.ramka-border-shadow {
  box-shadow: 1px 1px 3px 0px rgb(66, 68, 90);
}

.ramka-inset-shadow {
  box-shadow: inset 1px 1px 3px 0px rgb(66, 68, 90);
  padding: 3px 5px;
  border-radius: 3px;
}

.ramka-wnetrze-ramki {
  background-color: var(--wnetrzeRamki);
}

.w50 {
  width: 50px;
}

.w100 {
  width: 100px !important;
}

.p5 {
  padding: 5px;
}

span.green {
  color: green;
}
span.yellow {
  color: orange;
  font-weight: 800;
}
span.red {
  color: red;
  font-weight: 800;
}
span.pointer {
  cursor: pointer;
}
span.suma {
  font-weight: 800;
}

.central, .center {
  text-align: center;
}

.red {
  color: red;
}

.right {
  text-align: right;
}

.left {
  text-align: left !important;
}

.mt-100 {
  margin-top: 100px;
}

.pointer {
  cursor: pointer;
}

.comment {
  background-color: #e2faea;
  color: green;
  padding: 10px 10px;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 15px 0;
}

.cienie {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 1px 5px 18px -7px rgb(106, 106, 123);
}

.hidden {
  display: none;
}

ul {
  list-style: none;
}
h2 {
  width: -moz-fit-content;
  width: fit-content;
}/*# sourceMappingURL=style.css.map */