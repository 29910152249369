div.usterki {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.usterki div.wspolnoty-lista {
  width: 100%;
}
div.usterki div.oczekujace {
  margin-top: 30px;
  width: 100%;
  font-size: 14px;
}
div.usterki div.usterki-lista {
  margin: 50px 0;
  max-height: 500px;
  width: 100%;
  overflow: hidden;
}
div.usterki div.usterki-lista ul {
  padding: 10px 20px;
  list-style: none;
}
div.usterki div.usterki-lista ul li.g {
  background-color: var(--podswietlenieListy);
}
div.usterki form {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  width: -moz-fit-content;
  width: fit-content;
  padding: 30px 50px;
  border-radius: 5px;
  border: 1px solid var(--ramka);
}
div.usterki form button {
  width: -moz-fit-content;
  width: fit-content;
}
div.usterki form div.pytanie {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}
div.usterki form div.nowePytanie {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}
div.usterki form div.nowePytanie div.tresc {
  display: flex;
  align-items: center;
}
div.usterki form div.nowePytanie div.tresc button {
  display: block;
}/*# sourceMappingURL=usterki.css.map */