@charset "UTF-8";
@media (min-width: 320px) {
  div.mieszkaniec {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  div.mieszkaniec div.buttons {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  div.mieszkaniec div.buttons button {
    margin: 5px 10px;
  }
  div.mieszkaniec div.buttons button.raport {
    display: none;
  }
  div.mieszkaniec div.content {
    padding: 0;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer {
    display: flex;
    flex-direction: column;
    max-width: 480px;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.lista {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: var(--wnetrzeRamki);
    transition: background-color 0.5s;
    margin: 0px 0 10px 0;
    font-size: 0.9rem;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.lista h3 {
    font-size: 1.1rem;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.lista:hover {
    background-color: var(--wnetrzeRamkiHover);
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.lista div {
    width: -moz-fit-content;
    width: fit-content;
    width: 100%;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.lista div ul li {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    border: initial;
    font-weight: initial;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.lista div ul li div {
    flex: initial;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.lista div ul li div:first-child {
    width: 100px;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.lista div ul li div:last-child {
    width: -moz-fit-content;
    width: fit-content;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa {
    padding: 5px 10px;
    border-radius: 10px;
    background-color: var(--wnetrzeRamki);
    transition: background-color 0.5s;
    margin: 0 0 10px 0;
    cursor: pointer;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa .hover:hover {
    background-color: var(--wnetrzeRamkiHover);
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa div.naglowek h3 {
    padding: 0 0 5px 0;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa div.naglowek h5 {
    padding: 15px;
    width: -moz-fit-content;
    width: fit-content;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa ul.lista.udzialKonto {
    list-style: none;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa ul.lista.udzialKonto li {
    font-size: 12px;
    padding: 0;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa ul.lista.udzialKonto li:first-child, div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa ul.lista.udzialKonto li:last-child {
    display: flex;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa ul.lista.udzialKonto li div {
    display: flex;
    align-items: center;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa ul.lista.udzialKonto li div:first-child {
    margin: 0 15px 0 0;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa ul.lista.udzialKonto li div span {
    display: block;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 5px 5px 0;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa ul.lista.udzialKonto li div svg {
    padding: 5px 0;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa ul.lista.udzialKonto li div.numer {
    padding: 0 3px;
    border-radius: 3px;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa ul.lista.udzialKonto li div.numer:hover {
    background-color: var(--podswietlenieListy);
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa div.lokale {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa div.lokale h4 {
    margin: 0 0 5px 0;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa div.lokale ul {
    list-style: none;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa div.lokale ul li {
    display: flex;
    margin: 0;
    font-size: 12px;
    font-weight: initial;
    border: initial;
    padding: 2px 5px;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa div.lokale ul li div {
    flex: initial;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa div.lokale ul li div:first-child {
    width: 150px;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa div.lokale h5 {
    margin: 5px 0 5px 0;
  }
  div.mieszkaniec h4 {
    width: -moz-fit-content;
    width: fit-content;
  }
  div.mieszkaniec div.usterka {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  div.mieszkaniec div.usterka form {
    width: -moz-fit-content;
    width: fit-content;
  }
  div.mieszkaniec div.usterka form div {
    margin-top: 30px;
  }
  div.mieszkaniec div.usterka form div select {
    min-width: 165px;
  }
  div.mieszkaniec div.glosowanie div.lista-kart {
    width: 95%;
    padding: 10px;
    margin: 0 0 10px 0;
  }
  div.mieszkaniec div.glosowanie div.lista-kart div.lista-aktywne, div.mieszkaniec div.glosowanie div.lista-kart div.lista-archiwum {
    margin: 5px 0 10px 0;
  }
  div.mieszkaniec div.rozliczeniaContainer {
    display: flex;
    flex-direction: column;
    height: -moz-fit-content;
    height: fit-content;
    background-color: var(--wnetrzeRamki);
    padding: 10px 15px;
  }
  div.mieszkaniec div.rozliczeniaContainer h3 {
    font-size: 15px;
    font-weight: 600;
  }
  div.mieszkaniec div.rozliczeniaContainer div.stawkiAkt {
    display: flex;
    flex-direction: column;
    padding: 5px 0px !important;
  }
  div.mieszkaniec div.rozliczeniaContainer div.stawkiAkt div.naglowek {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
  div.mieszkaniec div.rozliczeniaContainer div.stawkiAkt div.naglowek svg {
    display: none;
  }
  div.mieszkaniec div.rozliczeniaContainer div.stawkiAkt ul {
    font-size: 12px;
  }
  div.mieszkaniec div.rozliczeniaContainer div.stawkiAkt ul li {
    display: flex;
    justify-content: space-between;
    padding: 2px;
    border-radius: 5px;
  }
  div.mieszkaniec div.rozliczeniaContainer div.stawkiAkt ul li.firstRow {
    font-weight: 600;
    border-bottom: 1px solid var(--ramka);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  div.mieszkaniec div.rozliczeniaContainer div.stawkiAkt ul li.firstRow:hover {
    background-color: initial;
  }
  div.mieszkaniec div.rozliczeniaContainer div.stawkiAkt ul li.lastRow {
    font-weight: 600;
    border-top: 1px solid var(--ramka);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  div.mieszkaniec div.rozliczeniaContainer div.stawkiAkt ul li.lastRow:hover {
    background-color: initial;
  }
  div.mieszkaniec div.rozliczeniaContainer div.stawkiAkt ul li.lastRow div:last-child {
    width: 70px;
  }
  div.mieszkaniec div.rozliczeniaContainer div.stawkiAkt ul li:hover {
    background-color: var(--podswietlenie);
  }
  div.mieszkaniec div.rozliczeniaContainer div.stawkiAkt ul li div {
    width: 50px;
    align-content: center;
  }
  div.mieszkaniec div.rozliczeniaContainer div.stawkiAkt ul li div:nth-child(1) {
    width: 130px;
  }
  div.mieszkaniec div.rozliczeniaContainer div.stawkiAkt ul li div:not(:first-child) {
    text-align: right;
  }
  div.mieszkaniec div.rozliczeniaContainer div.tabele {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  div.mieszkaniec div.rozliczeniaContainer div.tabele div {
    width: 100%;
    margin: 0 0 20px 0;
    max-height: 200px;
    overflow: auto;
  }
  div.mieszkaniec div.rozliczeniaContainer div.tabele div:first-child {
    overflow-x: auto;
  }
  div.mieszkaniec div.rozliczeniaContainer div.tabele div h4 {
    margin: 5px 0;
  }
  div.mieszkaniec div.rozliczeniaContainer div.tabele div table {
    font-size: 12px;
    border-collapse: collapse;
    width: 100%;
  }
  div.mieszkaniec div.rozliczeniaContainer div.tabele div table th {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding: 3px;
    border-bottom: 1px solid var(--ramka);
  }
  div.mieszkaniec div.rozliczeniaContainer div.tabele div table tbody td {
    white-space: nowrap;
    padding: 5px 10px;
    word-wrap: break-word;
  }
  div.mieszkaniec div.rozliczeniaContainer div.tabele div table tbody td:last-child {
    font-weight: 600;
  }
  div.mieszkaniec div.rozliczeniaContainer div.saldo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 0;
  }
  div.mieszkaniec div.rozliczeniaContainer div.saldo span {
    font-size: 14px;
  }
}
@media (min-width: 1024px) {
  div.mieszkaniec {
    flex-direction: column;
    align-items: center;
    padding: 20px 0 0 0;
  }
  div.mieszkaniec div.buttons {
    margin-bottom: 20px;
    width: 100%;
  }
  div.mieszkaniec div.buttons button.raport {
    display: block;
  }
  div.mieszkaniec h4 {
    padding: 10px 0;
  }
  div.mieszkaniec div.glosowanie {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    justify-content: space-around;
  }
  div.mieszkaniec div.glosowanie div.lista-kart {
    max-height: 500px;
    overflow: auto;
    padding: 10px;
  }
  div.mieszkaniec div.glosowanie div.lista-kart div.lista-aktywne div.kartaNaglowek, div.mieszkaniec div.glosowanie div.lista-kart div.lista-archiwum div.kartaNaglowek {
    padding: 10px 20px;
    background-color: var(--tlo);
    border: 1px solid var(--fontAwesome);
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.15s ease;
  }
  div.mieszkaniec div.glosowanie div.lista-kart div.lista-aktywne div.kartaNaglowek:hover, div.mieszkaniec div.glosowanie div.lista-kart div.lista-archiwum div.kartaNaglowek:hover {
    background-color: var(--wnetrzeRamkiHover);
  }
  div.mieszkaniec div.glosowanie div.lista-kart div.lista-aktywne div.kartaNaglowek.active, div.mieszkaniec div.glosowanie div.lista-kart div.lista-archiwum div.kartaNaglowek.active {
    background-color: var(--wnetrzeRamkiHover);
    border-width: 2px;
  }
  div.mieszkaniec div.glosowanie div.lista-kart div.lista-archiwum {
    margin-top: 50px;
  }
  div.mieszkaniec div.glosowanie div.karta {
    width: 400px;
    margin: 0 0 20px 0;
  }
  div.mieszkaniec div.glosowanie div.karta p.header {
    text-decoration: underline;
  }
  div.mieszkaniec div.glosowanie div.karta p.data {
    font-size: 15px;
    margin: 15px 0;
  }
  div.mieszkaniec div.glosowanie div.karta section.pytania {
    margin: 20px 0;
    font-size: 15px;
  }
  div.mieszkaniec div.glosowanie div.karta section.pytania div.pytanie {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    border-bottom: 1px solid var(--ramka);
  }
  div.mieszkaniec div.glosowanie div.karta section.pytania div.pytanie div.tresc {
    display: flex;
    align-items: center;
  }
  div.mieszkaniec div.content {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  div.mieszkaniec div.content.wspolnotaRozliczenie {
    flex-direction: column;
  }
  div.mieszkaniec div.content select {
    width: -moz-fit-content;
    width: fit-content;
    margin: 15px 0;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer {
    max-width: 400px;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.lista div ul li {
    margin: 10px 0 5px 0;
  }
  div.mieszkaniec div.content div.mieszkaniecDaneContainer div.grupy div.grupyLista div.grupa ul li div:first-child {
    width: 130px;
  }
  div.mieszkaniec div.content div.rozliczeniaContainer {
    width: 700px;
    max-height: 810px;
    overflow: auto;
  }
  div.mieszkaniec div.content div.rozliczeniaContainer div.stawkiAkt {
    padding: 0 0 0 20px;
  }
  div.mieszkaniec div.content div.rozliczeniaContainer div.stawkiAkt div.naglowek svg {
    display: block;
  }
  div.mieszkaniec div.content div.rozliczeniaContainer div.stawkiAkt div.mieszkaniecWydruk {
    position: fixed; /* Ustawiamy tło jako fixed, aby zajmowało cały ekran */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* 100% wysokości widoku przeglądarki */
    background-color: rgba(0, 0, 0, 0.5); /* Półprzezroczyste tło */
    z-index: 1; /* Tło powinno być poniżej modala */
    display: flex;
    justify-content: center;
    overflow: auto;
  }
  div.mieszkaniec div.content div.rozliczeniaContainer div.stawkiAkt div.mieszkaniecWydruk div.modal {
    background-color: white;
    margin: 50px 0;
    padding: 20px;
    height: -moz-fit-content;
    height: fit-content;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10; /* Modal powinien być nad tłem */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: -moz-fit-content;
    width: fit-content;
    box-sizing: border-box; /* Uwzględnia padding w wymiarach elementu */
  }
  div.mieszkaniec div.content div.rozliczeniaContainer div.stawkiAkt ul li {
    width: 100%;
  }
  div.mieszkaniec div.content div.rozliczeniaContainer div.tabele {
    padding: 0 20px;
  }
  div.mieszkaniec div.content div.rozliczeniaContainer div.saldo {
    padding: 20px 20px;
  }
  div.mieszkaniec div.serwis {
    width: 100%;
  }
  div.mieszkaniec div.serwis p {
    text-align: center;
    margin: 20px;
  }
  div.mieszkaniec div.glosowanie div.lista-kart {
    width: 500px;
  }
}
@media (min-width: 1280px) {
  div.mieszkaniec {
    padding: 20px 100px 0 100px;
  }
}
div.mieszkancy {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
div.mieszkancy div.left {
  display: flex;
  flex-direction: column;
}
div.mieszkancy div.left div.dane {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
div.mieszkancy div.left div.dane div.content div.addForm {
  display: flex;
  flex-direction: column;
  width: 500px;
}
div.mieszkancy div.left div.dane div.content div.addForm div.wiersz {
  justify-content: space-between;
  display: flex;
  margin: 3px 0;
}
div.mieszkancy div.left div.dane div.content div.addForm div.wiersz span, div.mieszkancy div.left div.dane div.content div.addForm div.wiersz input {
  display: block;
}
div.mieszkancy div.left div.dane div.listaMieszkancow {
  width: 550px;
}
div.mieszkancy div.left div.dane div.listaMieszkancow div.mieszkancyUlice {
  display: flex;
  flex-direction: column;
}
div.mieszkancy div.left div.dane div.listaMieszkancow div.mieszkancyUlice div.box {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  flex-wrap: wrap;
}
div.mieszkancy div.left div.dane div.listaMieszkancow div.mieszkancyUlice div.box div.ulicaBtn {
  padding: 5px 10px;
  font-size: 12px;
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--podswietlenieListy);
  border-radius: 5px;
  margin: 0 5px 3px 0;
}
div.mieszkancy div.left div.dane div.listaMieszkancow div.mieszkancyUlice div.box div.ulicaBtn.active {
  background-color: var(--fontAwesome);
  color: var(--tlo);
}
div.mieszkancy div.left div.dane div.listaMieszkancow ul.lista {
  width: 100%;
  font-size: 11px;
  max-height: 350px;
  overflow: auto;
  border-radius: 5px;
}
div.mieszkancy div.mieszkancyDane {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 700px;
  padding: 10px 10px 10px 0;
  max-height: 700px;
  overflow: auto;
}
div.mieszkancy div.mieszkancyDane svg {
  margin-left: 10px;
}
div.mieszkancy div.mieszkancyDane div.button button {
  width: -moz-fit-content;
  width: fit-content;
}
div.mieszkancy div.mieszkancyDane div.lewa, div.mieszkancy div.mieszkancyDane div.prawa {
  margin: 0 10px;
}
div.mieszkancy div.mieszkancyDane div.lewa {
  display: flex;
  flex-direction: column;
  max-width: 50%;
}
div.mieszkancy div.mieszkancyDane div.lewa div.ramka ul.mieszkancyDane li {
  width: 100%;
}
div.mieszkancy div.mieszkancyDane div.lewa div.ramka ul.mieszkancyDane li div {
  flex: initial;
  display: flex;
  align-items: center;
}
div.mieszkancy div.mieszkancyDane div.lewa div.ramka ul.mieszkancyDane li div input[type=text] {
  width: 150px;
  font-size: 12px;
}
div.mieszkancy div.mieszkancyDane div.lewa div.ramka ul.mieszkancyDane li div input[type=date] {
  margin-left: 15px;
}
div.mieszkancy div.mieszkancyDane div.lewa div.ramka ul.mieszkancyDane li div.pwd {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}
div.mieszkancy div.mieszkancyDane div.lewa div.ramka ul.mieszkancyDane li > div:first-child {
  width: 150px;
}
div.mieszkancy div.mieszkancyDane div.lewa div.saldo {
  font-size: 12px;
}
div.mieszkancy div.mieszkancyDane div.lewa div.saldo h2 {
  margin-bottom: 10px;
}
div.mieszkancy div.mieszkancyDane div.lewa div.saldo p {
  margin: 10px 0;
}
div.mieszkancy div.mieszkancyDane div.lewa div.naleznosci div.naleznosci table {
  font-size: 12px;
  width: 100%;
}
div.mieszkancy div.mieszkancyDane div.lewa div.naleznosci div.naleznosci table thead {
  text-align: left;
}
div.mieszkancy div.mieszkancyDane div.lewa div.mieszkancyHistoria {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 10px;
  font-size: 0.8rem;
  border: solid 1px var(--ramka);
  border-radius: 5px;
}
div.mieszkancy div.mieszkancyDane div.lewa div.mieszkancyHistoria div.data {
  font-weight: 800;
  font-size: 0.7rem;
  padding-bottom: 5px;
}
div.mieszkancy div.mieszkancyDane div.lewa div.mieszkancyHistoria div.wiersz {
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
}
div.mieszkancy div.mieszkancyDane div.lewa div.mieszkancyHistoria div.wiersz div {
  width: 50%;
}
div.mieszkancy div.mieszkancyDane div.lewa div.historia-wplat h2 {
  margin-bottom: 10px;
}
div.mieszkancy div.mieszkancyDane div.lewa div.historia-wplat ul.historiaWplat {
  font-size: 12px;
}
div.mieszkancy div.mieszkancyDane div.lewa div.historia-wplat ul.historiaWplat li {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  width: 100%;
}
div.mieszkancy div.mieszkancyDane div.lewa div.historia-wplat ul.historiaWplat li:hover {
  background-color: var(--podswietlenie);
}
div.mieszkancy div.mieszkancyDane div.lewa div.historia-wplat ul.historiaWplat span.brakWplat {
  display: block;
  margin: 5px;
}
div.mieszkancy div.mieszkancyDane div.prawa div.stawkiInd {
  font-size: 12px;
}
div.mieszkancy div.mieszkancyDane div.prawa div.stawkiInd div.wsad {
  display: flex;
}
div.mieszkancy div.mieszkancyDane div.prawa div.stawkiInd div.wsad div.form {
  width: 100%;
}
div.mieszkancy div.mieszkancyDane div.prawa div.stawkiInd div.wsad div.form ul.lista li {
  padding: 2px 0;
}
div.mieszkancy div.mieszkancyDane div.prawa div.stawkiInd div.wsad div.form ul.lista li div input {
  font-size: 12px;
}
div.mieszkancy div.mieszkancyDane div.prawa div.stawkiInd div.wsad div.daty {
  display: flex;
  width: 25%;
}
div.mieszkancy div.mieszkancyDane div.prawa div.stawkiInd div.wsad div.daty ul {
  width: 100%;
}
div.mieszkancy div.mieszkancyDane div.prawa div.stawkiInd div.wsad div.stawki {
  display: flex;
  width: 75%;
}
div.mieszkancy div.mieszkancyDane div.prawa div.stawkiInd div.wsad div.stawki ul {
  width: 100%;
}
div.mieszkancy div.mieszkancyDane div.prawa div.stawkiInd div.wsad div.stawki ul li:hover {
  background-color: initial;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial {
  min-width: 500px;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.naglowek {
  display: flex;
  justify-content: space-between;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial ul.daneGrupy {
  padding: 0 0 5px 0;
  width: 100%;
  font-size: 12px;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial ul.daneGrupy li {
  display: flex;
  flex: initial;
  padding: 5px 0 0 0;
  align-items: center;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial ul.daneGrupy li div.w150 {
  width: 170px;
  font-weight: 600;
  align-content: center;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial ul.daneGrupy li svg {
  margin-left: 20px;
  display: block;
  align-content: center;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial ul.daneGrupy li div:last-child {
  font-weight: initial;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial ul.daneGrupy li:not(:first-child):hover {
  background-color: initial;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.historia {
  display: flex;
  flex-direction: column;
  padding: 5px 15px 0 15px;
  font-size: 12px;
  border-radius: 5px;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.historia h2 {
  margin: 0px 0px 10px 20px;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.historia div.zmiana {
  border: solid 1px var(--ramka);
  border-radius: 5px;
  max-height: 300px;
  overflow: auto;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.historia div.zmiana ul.historiaZmian {
  list-style: none;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.historia div.zmiana ul.historiaZmian li {
  display: flex;
  padding: 5px 0;
  width: 100%;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.historia div.zmiana ul.historiaZmian li:hover {
  background-color: var(--podswietlenie);
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.historia div.zmiana ul.historiaZmian li div {
  margin: 1px 0px;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.historia div.zmiana ul.historiaZmian li div.kosz-con {
  display: flex;
  justify-content: center; /* Wypośrodkowanie horyzontalne */
  align-items: center; /* Wypośrodkowanie wertykalne */
  padding: 0 10px;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.historia div.zmiana ul.historiaZmian li div.kosz-con div.kosz {
  text-align: center;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.historia div.zmiana ul.historiaZmian li div.kosz-con div.kosz:hover {
  cursor: pointer;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.historia div.zmiana ul.historiaZmian li div.dane {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 1px 0px;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.historia div.zmiana ul.historiaZmian li div.dane div.daty {
  display: flex;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.historia div.zmiana ul.historiaZmian li div.dane div.daty div.opis {
  width: 60%;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.addLokalBox {
  display: flex;
  flex-direction: column;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.addLokalBox div.rodzaj {
  margin-top: 15px;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.addLokalBox div.rodzaj ul {
  font-size: 11px;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.addLokalBox div.rodzaj ul li {
  display: flex;
  justify-content: space-between;
  padding: 2px 0px;
  text-align: center;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.addLokalBox div.rodzaj ul li div {
  align-content: center;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.addLokalBox div.rodzaj ul.head {
  margin-right: 15px;
  font-weight: 600;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.addLokalBox div.rodzaj ul.head li:first-child {
  font-weight: 600;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.addLokalBox div.rodzaj ul.listaLokal {
  max-height: 150px;
  overflow: auto;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.addLokalBox div.rodzaj ul.listaLokal li {
  flex: initial;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.addLokalBox div.rodzaj ul.listaLokal li div svg {
  margin: 0;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.addLokalBox div.rodzaj ul.listaLokal li div input {
  padding: 2px;
  width: 90px;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.addLokalBox div.rodzaj ul.listaLokal li div:last-child {
  display: flex;
  justify-content: space-between;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.addLokalBox div.rodzaj ul.listaLokal li div:last-child div {
  align-items: center;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.addLokalBox div.rodzaj ul.listaLokal li div:last-child div svg {
  margin-left: 5px !important;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.addLokalBox div.rodzaj ul.listaLokal li div:first-child {
  text-align: left;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.lokale {
  padding: 5px 0px 0 15px;
  width: 100%;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.lokale h4 {
  margin: 0px 20px 0 20px;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.lokale div.lokal ul {
  padding: 0 0 5px 0;
  width: initial;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.lokale div.lokal ul li {
  padding: 5px 0 0 0;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.lokale div.lokal ul li div {
  flex: initial !important;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.lokale div.lokal ul li div.w150 {
  width: 150px;
  font-weight: 600;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.lokale div.lokal ul li div:last-child {
  font-weight: initial;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.lokale div.lokal ul li:not(:first-child):hover {
  background-color: initial;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.lokale div.lokal ul div.edit-data-od {
  display: flex;
  margin: 10px 0;
  align-items: center;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.lokale div.lokal ul div.edit-data-od div {
  width: 150px;
  font-weight: 600;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.lokale div.lokal ul div.edit-data-od svg {
  display: block;
  margin-left: 10px;
  padding: 2px 5px;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.grupa {
  margin: 0px 0px 0 10px;
  padding: 15px 0 15px 10px;
  background-color: var(--tlo);
  overflow: hidden;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.grupa div.naglowek {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.grupa div.naglowek h3 {
  display: block;
  margin: 15px 0 5px 5px;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.grupa div.naglowek h5 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.grupa div.naglowek div.buttons {
  display: flex;
  flex-direction: column;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.grupa ul.udzialKonto li div {
  flex: initial;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.grupa ul.udzialKonto li div:first-child {
  width: 200px;
  font-weight: 600;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.grupa ul.udzialKonto li div:last-child {
  font-weight: initial;
}
div.mieszkancy div.mieszkancyDane div.prawa div.grupaUdzial div.grupa ul.udzialKonto li:not(:first-child):hover {
  background-color: initial;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer h3, div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer h4 {
  margin: 20px 0 0px 20px;
  width: -moz-fit-content;
  width: fit-content;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer h3 span, div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer h4 span {
  font-size: 0.8rem;
  text-decoration: underline;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer h3 span:hover, div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer h4 span:hover {
  cursor: pointer;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer h4 {
  margin: 10px;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.lewa {
  display: flex;
  flex-direction: column;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.lewa div.ramka {
  padding: 10px;
  border: 1px solid var(--ramka);
  border-radius: 5px;
  height: -moz-fit-content;
  height: fit-content;
  min-width: 350px;
  background-color: var(--wnetrzeRamki);
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.lewa div.ramka div.lista {
  width: 100%;
  max-width: 450px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 10px;
  border-radius: 5px;
  background-color: var(--wnetrzeRamki);
  border: 1px solid var(--ramka);
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.lewa div.ramka div.lista ul.mieszkancyDane {
  width: 100%;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.lewa div.ramka div.lista ul.mieszkancyDane li {
  display: flex;
  padding: 5px 10px;
  border-bottom: solid 1px var(--ramka);
  border-radius: 0px;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.lewa div.ramka div.lista ul.mieszkancyDane li div.first {
  flex: initial;
  font-weight: 600;
  width: 150px;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.lewa div.ramka div.lista ul.mieszkancyDane li div.pwd {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  text-align: center;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.lewa div.ramka div.lista ul.mieszkancyDane li div.pwd div {
  font-weight: initial;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.lewa div.ramka div.lista ul.mieszkancyDane li div.pwd div:first-child {
  text-align: left;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.lewa div.ramka div.lista ul.mieszkancyDane li:first-child {
  font-weight: initial;
  border-bottom: solid 1px var(--ramka);
  border-radius: 0px;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.lewa div.ramka div.lista ul.mieszkancyDane li:hover {
  background-color: initial;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.lewa div.ramka div.lista div.form h4 {
  margin: 20px;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy {
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
  border: 1px solid var(--ramka);
  background-color: var(--wnetrzeRamki);
  border-radius: 5px;
  padding-right: 10px;
  padding-bottom: 10px;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form {
  margin: 10px 0px 0 10px;
  padding: 15px 20 15px 20px;
  background-color: var(--tlo);
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form h5 {
  margin: 20px 0 0 0;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.naglowek {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.naglowek h3 {
  display: block;
  margin: 15px 0 5px 5px;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.stawkiInd h5 {
  margin-bottom: 15px;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.stawkiInd input[type=date] {
  margin-bottom: 15px;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.stawkiInd div.stawkiIndHist {
  display: flex;
  flex-direction: column;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.stawkiInd div.stawkiIndHist ul {
  list-style: none;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.stawkiInd div.stawkiIndHist ul li {
  display: flex;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.stawkiInd div.stawkiIndHist ul li div {
  text-align: center;
  font-size: 14px;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.stawkiInd div.stawkiIndHist ul li div.b {
  font-weight: 600;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.stawkiInd div.listaInd {
  display: flex;
  flex-direction: column;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.stawkiInd div.listaInd div.listaIndWiersz {
  display: flex;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.stawkiInd div.listaInd div.listaIndWiersz div {
  width: 50%;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.stawkiInd div.listaInd div.listaIndWiersz div input {
  width: 100px;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.stawkiInd div.listaInd div.listaIndWiersz div.opis label.b {
  font-weight: 600;
  text-decoration: underline;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form ul.udzialKonto li {
  display: flex;
  justify-content: space-between;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form ul.udzialKonto li div {
  flex: initial;
  display: flex;
  align-items: center;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form ul.udzialKonto li div:first-child {
  width: 200px;
  font-weight: 600;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form ul.udzialKonto li div:last-child {
  font-weight: initial;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form ul.udzialKonto li:not(:first-child):hover {
  background-color: initial;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.listaBoxy {
  max-height: 300px;
  overflow: auto;
  margin: 5px 0 0 0;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.listaBoxy div.checkbox {
  display: flex;
  width: 100%;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.listaBoxy div.checkbox input, div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.listaBoxy div.checkbox label {
  display: inline-block;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.listaBoxy div.checkbox label {
  width: 100%;
  font-size: 12px;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.planowaneZuzycie {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  margin: 15px 0 0 0;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.planowaneZuzycie div.naglowek {
  height: 75px;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.planowaneZuzycie div.naglowek h4 {
  margin: 0 25px 25px 25px;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.planowaneZuzycie div.formularz {
  display: flex;
  flex-direction: column;
  width: 80%;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.planowaneZuzycie div.formularz div.wiersz {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  margin: 5px 0;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.planowaneZuzycie div.formularz div.wiersz label {
  padding: 5px;
  width: 200px;
  font-size: 14px;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.planowaneZuzycie div.formularz div.wiersz div.input {
  display: flex;
  flex-direction: row;
  align-items: center;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.planowaneZuzycie div.formularz div.wiersz div.input input {
  width: 150px;
  margin: 0 10px 0 0;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.planowaneZuzycie div.formularz div.wiersz div.input span {
  width: 20px;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.planowaneZuzycie div.formularz div.wiersz.button {
  justify-content: center;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.planowaneZuzycie div.formularz div.wiersz.button button {
  width: -moz-fit-content;
  width: fit-content;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.planowaneZuzycie div.lista {
  display: flex;
  flex-direction: column;
  width: 80%;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.planowaneZuzycie div.lista ul li {
  display: flex;
  flex-direction: row;
  margin: 15px 0;
  padding: 5px 10px;
  border-radius: 5px;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.planowaneZuzycie div.lista ul li div.dane {
  display: flex;
  flex-direction: column;
  width: 100%;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.planowaneZuzycie div.lista ul li div.dane div.wierszData {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 0 0 5px 0;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.planowaneZuzycie div.lista ul li div.dane div.wierszData div.value {
  font-weight: 600;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.planowaneZuzycie div.lista ul li div.dane div.wierszZuzycia {
  display: flex;
  flex-direction: row;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.planowaneZuzycie div.lista ul li div.dane div.wierszZuzycia div {
  width: 50%;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.planowaneZuzycie div.lista ul li div.kosz {
  min-width: 20px;
  font-size: 20px;
}
div.mieszkancy div.mieszkancyDane div.mieszkancyDaneContainer div.grupy div.form div.planowaneZuzycie div.lista ul li:hover {
  background-color: var(--podswietlenieListy);
}/*# sourceMappingURL=mieszkancy.css.map */