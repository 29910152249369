@media (min-width: 320px) {
    main {
        display: flex;
        justify-content: center;
        padding: 50px 0px;
        div.polityka {
            h2 {
                margin: 10px 0;
                font-size: 1rem;
            }
            p {
                text-align: justify;
                margin: 10px 0;
                ul {
                    padding-left: 25px;
                }
            }
            table {
                font-size: 5px;
                width: 100%;
            }
        }

    }
}

@media (min-width: 375px) {
    main {
        padding: 50px 10px;
        div.polityka {
            table {
                font-size: 7px;
            }
        }
    }
}

@media (min-width: 430px) {
    main {
        div.polityka {
            table {
                font-size: 8px;
            }
        }
    }
}


@media (min-width: 1024px) {
    main {
        div.polityka {
            width: 80%;
            margin: 100px 0;
            table {
                font-size: initial; 
            }
        }
    }   
}