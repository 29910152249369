div.serwis {
  width: 800px;
}
div.serwis div.formularz input[type=text] {
  width: 100%;
}
div.serwis div.formularz span {
  font-size: 13px;
}
div.serwis div.historia ul li {
  display: flex;
}
div.serwis div.historia ul li div {
  flex: initial;
  width: 150px;
}
div.serwis div.historia ul li div:nth-of-type(1) {
  width: 40px;
}
div.serwis div.historia ul li div:nth-of-type(2) {
  flex-grow: 1;
}
div.serwis div.historia ul li div:nth-of-type(3), div.serwis div.historia ul li div:nth-of-type(4) {
  text-align: center;
}
div.serwis div.historia ul li div:last-of-type {
  width: 30px;
  text-align: center;
}/*# sourceMappingURL=serwis.css.map */