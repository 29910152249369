@media (min-width: 320px) {
    div.content.ksiegowosc {
        div.buttons {
            flex-wrap: wrap;
            button {
                margin: 2px 4px;
            }
        }
        div.window {
        div.przelewy {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h4 {
                width: 100%;
            }
            div.import {
                display: flex;
                flex-direction: column;
                div.form {
                    display: flex;
                    align-items: center;
                }
                div.tabela {
                    
                }
            }
            div.pliki {
                width: 50%;
            }
            div.import, div.historia, div.pliki {
                margin: 20px 0px 10px 0px;

                select {
                    margin: 20px;
                }
                input {
                    margin: 10px 20px 30px 50px;
                }
                h5 {
                    margin: 10px 0 30px 0;
                }
                table {
                    font-size: 10px;
                    width: 100%;
                    tbody {
                        tr {
                            &:hover {
                                background-color: var(--podswietlenieListy);
                            }
                            &.g {
                                background-color: var(--podswietlenie);
                            }
                            th {
                                text-align: left;
                                border-bottom: 1px solid var(--ramka);
                                padding: 5px 5px;
                            }
                            td {
                                padding: 3px 5px;
                                select {
                                    margin: 0;
                                    padding: 1px 5px;
                                }
                                &.ost {
                                    display: flex;
                                    height: 30px;
                                    align-items: center;
                                    span {
                                        display: block;
                                        width: 15px;
                                        margin: 0 5px;
                                    }
                                }
                            }
                        }
                    }
                }
                div.send {
                    display: flex;
                    justify-content: center;
                }
            }
            p.saldo_w {
                margin-bottom: 30px;
            }
            div.przeksiegowaniaForm {
                display: flex;
                flex-direction: column;
                width: 80%;
                div.listy {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    margin: 10px 0;
                    div.zNa {
                        display: flex;
                        justify-content: center;
                        width: 45%;
                        align-content: center;
                        align-items: center;
                        span {
                            display: block;
                            margin: 0 20px 0 0;
                        }
                    }
                }
                div.form {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 10px 0;
                    div {
                        display: flex;
                        align-items: center;
                        margin: 0 15px;
                        input, button {
                            margin: 0 10px;
                            display: block
                        };
                        input.opis {
                            width: 200px;
                        }

                    }

                    input {
                        // width: 100px;
                        height: 30px;
                    }

                }
            }
        }

        div.historia {
            display: flex;
            flex-direction: column;
            div.filtry {
                display: flex;
                justify-content: flex-start;
                padding: 0 15px 15px 15px;
            }
            div.tabela {
                max-height: 500px;
                overflow: auto;
                table {
                    border-collapse: separate;
                    border-spacing: 0;
                    thead {
                        
                        tr th {
                            position: sticky;
                            top: 0;
                            z-index: 1;
                            background-color: var(--tlo);
                        }
                    }
                } 
            }
            
        }
    }
}

}

@media (min-width: 1024px) {
    div.content.ksiegowosc {
        div.buttons {
            button {
                margin: 10px 20px;
            }
        }
    }
}





