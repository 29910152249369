div.grupaWoda {
    >div {
        margin: 0 0 15px 0;
        div.naglowek {
            display: flex;
            margin: 0 0 5px 0;
            h5 {
                width: fit-content;
            }
            div {
                display: flex;
                margin: 0 10px;
                svg {
                    margin: 0 0 0 20px;
                }
            }
        }
        div.form {
            display: flex;
            justify-content: space-between;
            margin: 10px 0;
            font-size: 12px;
            div.left, div.right {
                label, input {
                    display: block;
                    font-size: 12px;
                }
                label {
                    text-align: left;
                }
                input {
                    margin: 0 15px 0 0;
                }
                input[type=number], input[type=text] {
                    width: 100px;
                    
                }
                div.row {
                    display: flex;
                }
            }
            div.right {
                display: flex;
                flex-shrink: 1;
                flex-direction: column;
            }
            
        }
        ul {
            font-size: 12px;
            li {
                display: flex;
                justify-content: space-between;
                width: 100%;
                &.wiersz {
                    
                    div {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between  ;
                    }
                }
            }
        }
        &.planpowaneZuzycie {
            // width: fit-content;
            
            &.rozliczenie {
                
                div.dane {
                    display: flex;
                    flex-direction: column;
                    font-size: 12px;
                    div.tabele {
                        display: flex;
                        flex-direction: row;
                        div.lewa, div.prawa {
                            width: 100%;
                            margin: 0;
                            div.naglowek {
                                display: flex;
                                margin: 10px 0;
                                justify-content: center;
                                font-weight: 500;
                            }
                        }
                        div.lewa {
                            display: flex;
                            flex-direction: column;
                            table {
                                text-align: center;
                            }
                            
                        }
                    }
                    div.koszt {
                        display: flex;
                        flex-direction: column;
                        div.naglowek {
                            display: flex;
                            margin: 10px 0;
                            justify-content: center;
                            font-weight: 500;
                        }
                        div.kalkulacje {
                            p {
                                margin: 0 0 5px 5px;
                                text-align: right;
                                padding: 0 20px 0 0;
                            }
                        }
                        div.podsumowanie {
                            display: flex;
                            flex-direction: column;
                            div {
                                display: flex;
                                width: 100%;
                                justify-content: center;
                                table {
                                   margin-bottom: 5px;
                                }
                            }
                        }
                    }
                    div.suma {
                        margin: 5px 10px;
                        p {
                            text-align: center;
                            font-weight: 600;
                            margin: 10px
                        }
                    }
                }
            }
            ul.odczyty {
                li {
                    display: flex;
                    justify-content: space-between;
                    padding: 0;
                    width: 100%;
                    div {
                        flex: initial;
                    }
                    div.w50 {
                        width: 50px;
                    }
                    div.w100 {
                        width: 100px;
                    }
                }
            }
        }
    }
}

div.woda {
    div.naglowek {
        justify-content: center;
        &.rozliczenie-daty {
            display: flex;
            flex-direction: column;
            width: 50%;
            justify-content: left;
        }
        div.listaWspolnot {
            padding: 40px 0;
        }
        div.cena {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            h4 {
                margin: 5px;
                width: 400px;
                text-align: center;
                
                &.c {
                    border-left: 1px solid #e9ecef;
                    border-top: 1px solid #e9ecef;
                    border-right: 1px solid #e9ecef;
                    padding: 15px 10px 0 10px;
                }
                &.z {
                    border-left: 1px solid #e9ecef;
                    border-bottom: 1px solid #e9ecef;
                    border-right: 1px solid #e9ecef;
                    padding: 0px 10px 15px 10px;
                }   
            }
        }
    }
    
    div.container {
        display: flex;
        justify-content: space-between;
        margin: 10px 20px 0px 20px;
        div.listaMieszkancow {
            display: flex;
            width: 550px;
            max-height: 600px;
            overflow: auto;
            ul.lista {
                width: 100%;
                margin: 0 25px 0 0 ;
                li {
                    display: flex;
                    div{
                        &:first-child {
                            flex: 0 0 60%;
                        }   
                        &:nth-child(2) {
                            flex: 1; 
                          }
                    }
                    
                }
            }
        }
        div.rightSide {
            display: flex;
            flex-direction: column;
            min-width: 40%;
            // justify-content: center;
            align-items: center;
            font-size: 14px;
            div.rozliczenie {
                display: flex;
                width: 100%;
                div.wodaRozliczenieWydruk div.modal div.A4 {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    section {
                        display: flex;
                        &.rozliczenie-wody-wydruk-nadawca-data {
                            justify-content: space-between;
                        }
                        &.rozliczenie-wody-wydruk-adresat {
                            justify-content: flex-end;
                            font-weight: 500;
                        }
                        &.rozliczenie-wody-wydruk-naglowek {
                            justify-content: center;
                            h4 {
                                text-transform: uppercase;
                            }
                        }
                        &.rozliczenie-wody-wydruk-tabele {
                            flex-direction: row;
                            justify-content: space-around;
                        }
                        &.rozliczenie-wody-wydruk-uwaga {}
                    }
                }
                ul {
                    list-style: none;
                    width: 100%;
                    li {
                        display: flex;
                        flex-direction: column;
                        div.rozliczenie-mieszkaniec-dane {
                            display: flex;
                            flex-direction: column;
                            padding-left: 10px;
                            div.rozliczenie-liczniki-stawki {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-around;
                                margin: 15px 0;
                                div {
                                    table {
                                        font-size: 11px;
                                    }
                                }
                                div.rozliczenie-liczniki {
                                    margin-right: 15px;
                                    table {
                                        tbody tr {
                                            td:not(:first-child) {
                                                text-align: center;
                                            }
                                        }
                                        
                                    }
                                }
                                div.rozliczenie-stawki {
                                    table {
                                        tbody tr {
                                            td:not(:first-child) {
                                                text-align: right;
                                            }
                                        }
                                        
                                    }
                                }
                            }
                            div.rozliczenie-naliczenia-razem {
                                table {
                                    font-size: 11px;
                                    thead tr {
                                        th {
                                            text-align: center;
                                        }
                                    }
                                    tbody tr {
                                        td:not(:first-child) {
                                            text-align: right;
                                        }
                                        td.saldo {
                                            font-weight: 500;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            div.planowaneZuzycie {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex-grow: 1;
                width: 100%;
                div.naglowek {
                    height: 75px;
                    h4 {
                        margin: 0 25px 25px 25px;
                    }
                }
                div.formularz {
                    display: flex;
                    flex-direction: column;
                    width: 80%;
                    div.wiersz {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        flex-grow:1;
                        margin: 5px 0;
                        
                        label {
                            padding: 5px;
                            width: 200px;
                            font-size: 14px;
                        }
                        div.input {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            input {
                                width: 150px;
                                margin: 0 10px 0 0 ;
                            }
                            span {
                                width: 20px;
                                
                            }
                        }
                        &.button {
                            justify-content: center;
                            button {
                                width: fit-content;
                            }
                        }

                    }

                }
                div.lista {
                    display: flex;
                    flex-direction: column;
                    width: 80%;
                    ul {
                        li {
                            display: flex;
                            flex-direction: row;
                            margin: 15px 0;
                            padding: 5px 10px;
                            border-radius: 5px;
                            div.dane {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                div.wierszData {
                                    display: flex;
                                    justify-content: space-between;
                                    width: 50%;
                                    margin: 0 0 5px 0;
                                    div.value {
                                        font-weight: 600;
                                    } 
                                }
                                div.wierszZuzycia {
                                    display: flex;
                                    flex-direction: row;
                                    div {
                                        width: 50%;
                                    }
                                }
                            }
                            div.kosz {
                                min-width: 20px;
                                font-size: 20px;
                            }
                            &:hover {
                                background-color: var(--podswietlenieListy)
                            }
                        }
                    }
                }
            }
            div.formularz {
                border: 1px solid #e9ecef;
                width: 400px;
                label {
                    font-size: 14px!important;  
                }
                p {
                    margin: 10px;
                    text-decoration: underline;
                }
                div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 3px 0;
                    span {
                        display: block;
                    }
                }
                input {
                    width: 150px;
                }
                div.zuzycie {
                    .label {
                        padding: 5px;
                        width: 200px;
                    }
                    .value {
                        width: 20%;
                        // padding-left: 12px;
                    }
                }
                
            }
            div.listaLicznikow {
                padding: 15px;
                border: 1px solid #e9ecef;
                width: 400px;
                font-size: 12px;
                ul {
                    li {
                        display: flex;
                        flex-direction: column;
                        border: 1px solid #e9ecef;
                        padding: 5px 0;
                        div.poczatek, div.koniec {
                            padding: 0px 10px;
                            position: relative;
                            div.data {
                                padding: 5px 0;
                                svg {
                                    position: absolute;
                                    top: 10px;
                                    right: 10px;
                                }
                            }
                            div.naglowek, div.data, div.stan {
                                display: flex;
                                width: 100%;
                                div.opis {
                                    width: 50%;
                                }
                                div.value {
                                    width: 25%;
                                }
                                
                            }
                            div.naglowek {
                                margin: 1px 0 5px 0;
                            }
                        }
                        div.stan.ciepla {
                            padding-top: 5px;
                        }
                        div.zuzycie {
                            display: flex;
                            padding: 0px 10px;
                            &.ciepla {
                                padding-top: 5px;
                            }
                            div.opis {
                                 width: 50%;
                            }
                            div.value {
                                width: 25%;
                            }
                        }
                    }
                }
            }
        }

    }
}

div.mieszkaniecWoda {
    margin: 20px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    div.form {

    }
    div.arch {
        width: 50%;
        padding-left: 50px;
    }
}