div.content.logi {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
div.content.logi h3 {
  margin: 15px 0;
  width: 100%;
}
div.content.logi div.filtrBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 5px 5px;
}
div.content.logi div.filtrBox div.box {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  flex-wrap: wrap;
}
div.content.logi div.filtrBox div.box div.filtrBtn {
  padding: 5px 10px;
  font-size: 12px;
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--podswietlenieListy);
  border-radius: 5px;
  margin: 0 5px 3px 0;
}
div.content.logi div.filtrBox div.box div.filtrBtn.active {
  background-color: var(--fontAwesome);
  color: var(--tlo);
}
div.content.logi div.table {
  width: -moz-fit-content;
  width: fit-content;
  max-height: 350px;
  overflow: auto;
}
div.content.logi div.table table.logi {
  width: -moz-fit-content;
  width: fit-content;
  font-size: 11px;
}
div.content.logi div.table table.logi thead tr {
  text-align: right;
}
div.content.logi div.table table.logi thead tr th {
  text-align: center;
  padding: 5px 10px;
}
div.content.logi div.table table.logi tbody tr {
  text-align: right;
}
div.content.logi div.table table.logi tbody tr:hover {
  background-color: var(--podswietlenieListy);
}
div.content.logi div.table table.logi tbody tr td {
  text-align: center;
  padding: 5px 10px;
}/*# sourceMappingURL=logi.css.map */