div.usterki {
    display: flex;
    flex-direction: column;
    align-items: center;
    div.wspolnoty-lista {
        width: 100%;
    }
    div.oczekujace {
        margin-top: 30px;
        width: 100%;
        font-size: 14px;
    }
    div.usterki-lista {
        margin: 50px 0;
        
        max-height: 500px;
        width: 100%;
        overflow: hidden;
        ul {
            padding: 10px 20px;
            list-style: none;
            li.g {
                background-color: var(--podswietlenieListy);
            }
            
        }
    }
    form {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 500;
        width: fit-content;
        padding: 30px 50px;
        border-radius: 5px;
        border: 1px solid var(--ramka);
        button {
            width: fit-content;
        }
        div.pytanie {
            display: flex;
            justify-content: space-between;
            padding: 5px 0;
        }
        div.nowePytanie {
            display: flex;
            flex-direction: column;
            // align-items: center;
            margin: 10px 0; 
            div.tresc {
                display: flex;
                align-items: center;
                button {
                    display: block;
                }
            }


        }
    }
}